/** =============== Responsive CSS =============== **/

/** ==== Based on max-width ==== **/
@media (max-width: 1550px) {

  .cart .note-block,
  .cart .single_add_to_cart_button,
  .cart .product_type_simple.add_to_cart_button,
  .singleAddtoCart   {
    font-size: 1.5rem;
  }
}

//Extra Large devices

@media (max-width: 1450px) {
  html {
    font-size: 13px;
  }
}

// Large devices
@media (max-width: 1024px) {

  html {
    font-size: 11px;
  }

  .banner {
    min-height: auto !important;
  }

  .btn-links {
    .btn {
      font-size: 2rem;
    }
  }

}

// Medium devices
@media (max-width: 991px) {

  /* header */
  .main-header {
    .menu {
      padding-top: 45px;
      display: block;

      li {
        margin-bottom: 20px;

        a {
          font-size: 35px;
        }

        &:last-child {
          border-radius: 14px;
          -webkit-border-radius: 14px;
          -moz-border-radius: 14px;
          -ms-border-radius: 14px;
          -o-border-radius: 14px;

          a {
            font-size: 35px;
          }
        }
      }
    }

    .navbar-collapse {
      /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#060b2d+0,060e31+20,060e31+20,081641+27,081641+27,081641+32,092559+55,092559+66,092559+66,0b326e+100,070f33+100,2989d8+100,7db9e8+100 */
      background: rgb(6, 11, 45);
      /* Old browsers */
      background: -moz-linear-gradient(top, rgba(6, 11, 45, 1) 0%, rgba(6, 14, 49, 1) 20%, rgba(6, 14, 49, 1) 20%, rgba(8, 22, 65, 1) 27%, rgba(8, 22, 65, 1) 27%, rgba(8, 22, 65, 1) 32%, rgba(9, 37, 89, 1) 55%, rgba(9, 37, 89, 1) 66%, rgba(9, 37, 89, 1) 66%, rgba(11, 50, 110, 1) 100%, rgba(7, 15, 51, 1) 100%, rgba(41, 137, 216, 1) 100%, rgba(125, 185, 232, 1) 100%);
      /* FF3.6-15 */
      background: -webkit-linear-gradient(top, rgba(6, 11, 45, 1) 0%, rgba(6, 14, 49, 1) 20%, rgba(6, 14, 49, 1) 20%, rgba(8, 22, 65, 1) 27%, rgba(8, 22, 65, 1) 27%, rgba(8, 22, 65, 1) 32%, rgba(9, 37, 89, 1) 55%, rgba(9, 37, 89, 1) 66%, rgba(9, 37, 89, 1) 66%, rgba(11, 50, 110, 1) 100%, rgba(7, 15, 51, 1) 100%, rgba(41, 137, 216, 1) 100%, rgba(125, 185, 232, 1) 100%);
      /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to bottom, rgba(6, 11, 45, 1) 0%, rgba(6, 14, 49, 1) 20%, rgba(6, 14, 49, 1) 20%, rgba(8, 22, 65, 1) 27%, rgba(8, 22, 65, 1) 27%, rgba(8, 22, 65, 1) 32%, rgba(9, 37, 89, 1) 55%, rgba(9, 37, 89, 1) 66%, rgba(9, 37, 89, 1) 66%, rgba(11, 50, 110, 1) 100%, rgba(7, 15, 51, 1) 100%, rgba(41, 137, 216, 1) 100%, rgba(125, 185, 232, 1) 100%);
      /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#060b2d', endColorstr='#7db9e8', GradientType=0);
      /* IE6-9 */

    }

    .navbar-nav+.btn {
      border-radius: 14px;
      margin-top: 20px;
    }

    .nav-link {
      font-size: 25px;
    }

  }

  .navbar-toggler[aria-expanded="true"] {
    .icon-bar {
      position: relative;

      &:nth-child(1) {
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        top: 3px;
        left: -3px;
      }

      &:nth-child(2) {
        display: none;
      }

      &:nth-child(3) {
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        top: -5px;
        left: -3px;
      }
    }
  }

  .navbar-toggler {
    width: 55px;
    outline: none;
    padding: 1.2rem 0.75rem;
    z-index: 3;
    border: 0;

    &:focus {
      outline: none;
    }

    .icon-bar {
      height: 4px;
      background-color: $secondary;
      display: -ms-inline-flexbox;
      display: block;
      border-radius: 2px;
      transition: .25s linear;
      -webkit-transition: .25s linear;
      -moz-transition: .25s linear;
      -ms-transition: .25s linear;
      -o-transition: .25s linear;
      margin-bottom: 4px;

    }
  }

  .navbar-collapse {
    position: fixed;
    left: -100%;
    width: 100vw;
    background-color: $primary;
    height: 100vh !important;
    transition: left .5s ease;
    top: 0;
    display: block !important;
    opacity: 0;
    padding: 25px;
    overflow: auto;
    z-index: 2;

    &.show {
      left: 0 !important;
      opacity: 1 !important;
    }
  }

  .menu-button {
    position: absolute;
    right: 15px;
    margin-top: 1.2rem;

    &[aria-expanded="true"] {
      .icon-bar {
        background-color: $white;
      }
    }

    .icon-bar {
      background-color: $primary;
    }
  }

  .main-content {
    .container {
      padding: 6rem 15px;
    }
  }

  .wc-memberships-members-area-pagination .wc-memberships-members-area-pagination-next {
    font-size: 20px;
  }

  .wc-memberships-members-area-pagination .wc-memberships-members-area-pagination-last {
    font-size: 26px;
    margin-top: 0;
  }
    /* footer */
    .social-links{
      a,a .fa{

          padding: 7px;
     
      }
  }
    .main-footer {
      .row {
        position: relative;
        padding-top: 80px;
      }
  
      .custom-logo-link {
        height: 60px;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
      }
  
      .col-lg-4 {
        position: static;
      }
    }
}

// Small devices
@media (max-width: 768px) {

  input,
  textarea,
  select {
    font-size: 16px !important;
  }

  .joinUsRenew,.singleAddtoCart {
     font-size: 2.5rem !important;
  }

  .cart a.single_add_to_cart_button,
  .cart a.single_add_to_cart_button+.note-block,.singleAddtoCart+.note-block{
    margin-left: 0 !important;
  }



  .subscription_details {
    td {
      a {
        text-align: left !important;
        width: auto !important;
      }
    }
  }

  table {

    th,
    td {
      font-size: 2rem !important;
    }
  }

  .trading-sec h2 {
    font-size: 3rem;
  }

  .join-now {
    padding-left: 8rem;
    padding-right: 7rem;
  }

  .join-now a {
    padding-left: 3rem;
    font-size: 1.8rem;
    padding-right: 3rem;
  }

  .free-list li {
    font-size: 1.7rem;
  }

  .btn-links .btn {
    font-size: 1.6rem;
  }

  .trading-info-sec h2 {
    font-size: 4rem;
  }

  .trading-info-sec .h3,
  .trading-info-sec h3 {
    font-size: 2.75rem;
  }

  .copy-right {
    font-size: 1rem;
  }



  main {
    min-height: 74vh;
  }

  .checkout .bitpay_logo {
    width: 18rem;
    margin-bottom: 11px;
  }

  .woocommerce-checkout-payment label[for=wc-square-credit-card-tokenize-payment-method] {
    padding-left: 2.5rem !important;
  }

  .checkout .woocommerce-checkout-review-order .woocommerce-form__label-for-checkbox .woocommerce-terms-and-conditions-checkbox-text a {
    padding-left: 0;
  }

  .subscription-detail .entry-summary {
    margin: 20px;
  }

  .checkout .woocommerce-checkout-review-order .woocommerce-form__label-for-checkbox .woocommerce-form__input-checkbox {
    width: 21px;
    height: 21px;
    padding-left: 0 !important;
  }

  #add_payment_method .woocommerce-Payment .woocommerce-PaymentMethods input[type=radio] {
    top: 3px;
  }

  .recordings-title {
    display: none;
  }

  .trading-recordings {

    .card-body {
      padding-right: 0 !important;
    }

    .card-title {
      width: calc(100% - 100px);
    }

    .col-md-3 {
      position: static;

      p {
        position: absolute;
        right: 15px;
        top: 8px;
      }
    }
  }

  .main-footer {
    .col-lg-4 {
      order: 2;
      margin-top: 20px;
    }

    .col-lg-8 {
      order: 1;
    }
  }


  #wc-memberships-members-area-section {
    overflow-x: auto;
  }



  label {
    font-size: 1.8rem;
  }

  .sabai-widget-questions-search,
  .search-position {
    position: static;
    width: 100%;
    margin-bottom: 32px;
  }

  .modal-dialog {
    max-width: 100%;
  }

  .sabai-entity-filter-form {
    .sabai-row {
      margin-left: 0;
      margin-right: 0;
    }

    .sabai-col-md-12 {
      width: 100%;
      padding-left: 0;
      padding-right: 0;
    }
  }

  .sabai-entity-filter-form .sabai-taxonomy-term-0 {
    display: none !important;
  }

  .sabai-entity-filter-form .sabai-form-fields .sabai-form-type-select {
    margin-bottom: 0;

    select[name=questions_tags] {
      height: 100%;
      background-size: 16px;
    }
  }


  .woocommerce-checkout .select2-container {
    margin-top: 0;
  }

  .checkout .woocommerce-checkout-review-order-table {
    margin-top: 0;
  }


  .astrike-styling {
    top: 8px;
    left: 12px;
  }

  .banner-content {
    p {
      line-height: 2.8rem;
      font-size: 2.3rem;
    }

    h5 {
      margin-bottom: 4rem !important;
      font-size: 3rem;
    }
  }


  .free-list {
    margin-top: 20px;
  }


  .search-field {
    width: 100%;
  }

  .search-form {
    label {
      display: block;

    }
  }

  /* profile */

  .woocommerce-MyAccount-content .account-orders-table {
    tr {
      border-bottom: 1px solid $gray-400;
    }

    td {
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
  }

  /* footer */

  .main-footer {
    li {
      margin-bottom: 10px;
    }

    [class*="col"] {

      img,
      span {
        margin-bottom: 10px !important;
      }
    }

    [class*="col"]:last-of-type {
      li {
        margin-bottom: 0 !important;
      }
    }
  }


}


// Extra small devices
@media (max-width: 575px) {
  html {
    font-size: 8px;
  }

  .account-block{
    .woocommerce-Message--info{
     flex-direction: column;
     .woocommerce-Button{
       margin-bottom: 15px;
     }
    }
    
}

  .account-orders-table {
    td {
      border-left: 0 !important;
    }
  }

  .trading-sec {
    padding-top: 0 !important;
    background: initial;

    .col-md-7 {
      margin-top: 20px;
    }

    .img-wrap {
      position: static;
      width: 100%;
      margin-bottom: 20px;
    }
  }

  .sabai-entity-filter-form .sabai-form-fields .sabai-form-type-select select[name=questions_tags] {
    margin-top: 20px;
  }

  table td,
  table th {
    font-size: 1.5rem !important;
  }

  .checkout .woocommerce-checkout-review-order .woocommerce-form__label-for-checkbox .woocommerce-terms-and-conditions-checkbox-text a {
    padding-left: 39px;
  }
}

/** ==== Based on min-width ==== **/
@media(min-width: 1550px) {
  .container {
    max-width: 1450px;
  }
}

@media(min-width: 767px) {
.main-footer{
.social-links{
  line-height: 1;
  margin-top: 15px;
}
}
  .my_membership_settings {
    width: auto !important;
  }

  .search-form {
    display: flex;
    justify-content: center;
    align-items: center;

    label {
      display: flex;
      align-items: center;
      margin-bottom: 0;
    }

    .screen-reader-text {
      min-width: 160px;
    }

    .search-submit {
      margin-left: 1rem;
    }
  }
}

@media(min-width: 992px) {
  .menu-footer-nav-container {
    padding-left: 12%;
  }

  .main-sidebar+.main-content {
    width: calc(100% - 300px);

  }

  .main-header {
    padding-bottom: 2rem;

    .menu {
      background-color: #1C1C22;
      box-shadow: 2px 2px 5px #00000096;
      text-transform: uppercase;
      border-radius: 14px;
      -webkit-border-radius: 14px;
      -moz-border-radius: 14px;
      -ms-border-radius: 14px;
      -o-border-radius: 14px;

      li {
        display: inline-block;


        &:first-child {
          padding-left: 20px;
        }

        &:last-child {
          margin-left: 20px;
        }

      }
    }
  }

  .side-bar {
    &.collapse {
      display: block !important;
    }
  }
}


@media (min-width: 1200px) {
  .member-logged-in {
    .login-form {
      max-width: 1140px;
    }
  }
}


@media (min-width: 576px) {}

@media (min-width: 1024px) and (max-width: 1200px) {

  .main-header {
    .custom-logo-link {
      max-width: 30rem;
    }

    .menu {

      li {
        padding: 1.2rem 10px;

        a {
          font-size: 1.9rem;
        }
      }
    }
  }
}

@media (min-width: 1450px) and (max-width: 1550px) {
  .main-header {
    .menu {

      li {
        padding: 1.2rem 12px;

        a {
          font-size: 1.7rem;
        }
      }
    }
  }
}