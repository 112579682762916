.sabai-questions-questions {
    padding: 0;

    .sabai-questions-noanswers {
        background-color: $white !important;
    }

    .center-line {
        padding-left: 2rem;

        &:before {
            left: 2rem;
        }

        span {
            font-size: 1.875rem;
        }
    }

    .row {
        margin: 5px 0 0 !important;
        border: 0 !important;
        width: 100% !important;
        padding: 1.5rem 0 !important;
        background-color: $white !important;

        &:before {
            display: none !important;
        }

        a {
            text-decoration: none;
        }

        .col-4 {
            a {
                font-size: 1.563rem;
                font-family: chypre-normal;
                font-weight: 500;
            }
        }

        &.sabai-questions-featured {
            background-color: $light-blue;

            div {
                font-size: 15px;
            }
        }

        div {
            font-size: 15px;
            font-family: $primary-font;
            color: $primary;
            line-height: 1;
        }
    }
}


.sabai-questions-title {
    a {
        color: $primary;
    }
}

.sabai-number {
    color: $primary;
}

.sabai-pull-right {
    a {
        line-height: 1;
    }
}

.listing-wrap {
    margin-bottom: 5rem;
}

.selectric {
    border: 0;

    span,
    b {
        display: none !important;
    }
}

.selectric-items {
    position: static;
    border: 0;
    display: inline-block;

    .selectric-scroll {
        ul {
            display: flex;
            background-color: $white;
            box-shadow: 0px 0px 4px #00000029;
            border-radius: 0.5625rem;
            -webkit-border-radius: 0.5625rem;
            -moz-border-radius: 0.5625rem;
            -ms-border-radius: 0.5625rem;
            -o-border-radius: 0.5625rem;

            li {
                background-color: transparent;
                font-size: 1.563rem;
                color: $body-color;
                font-family: $primary-font;
                text-transform: uppercase;
                position: relative;
                white-space: nowrap;

                &:first-of-type {
                    display: none;
                }

                &:hover,
                &.selected {
                    color: $gray-600;
                }

                &:nth-child(2) {
                    &::after {
                        display: none;
                    }
                }

                &:after {
                    content: "";
                    position: absolute;
                    top: 10px;
                    left: 0;
                    height: calc(100% - 20px);
                    width: 2px;
                    background-color: $body-color;
                }
            }
        }
    }
}

.sabai-form.sabai-entity-filter-form {
    .selectric-items {
        .selectric-scroll {
            ul {
                li {

                    &:first-of-type {
                        display: block !important;
                    }

                    &:hover {
                        color: $gray-600;
                    }

                    &:nth-child(2) {
                        &::after {
                            display: block !important;
                        }
                    }

                    &:nth-child(1) {
                        &::after {
                            display: none !important;
                        }
                    }

                }
            }
        }
    }
}

.sabai-entity-label-featured {
    background-color: $primary;
}

.sabai-user-with-thumbnail {
    background: none !important;
    padding-left: 0 !important;
    word-break: break-all;
    line-height: 1;
    color: $primary;

    a {
        text-decoration: none;

        &:hover {
            text-decoration: none;
        }
    }
}



.sabai-pull-right {
    line-height: 1;
}

.sabai-questions-featured {
    .sabai-entity-links {
        opacity: 1;
        visibility: initial;
    }

    .sabai-row {
        margin-left: 0;
        margin-right: 0;
    }

    h2 {
        font-size: 1.875rem;
    }

    p {
        font-size: 1.25rem;
        line-height: 1.5rem;
    }

    .sabai-questions-body {
        div {
            margin-bottom: 2rem;
        }

    }
}

.sabai-questions-comments,
.sabai-questions-taxonomy,
.sabai-entity-activity,
.sabai-questions-add-answer-form,
.sabai-system-user-activity {
    a {
        color: $primary;
    }
}


.sabai-entity-filter-form {
    margin-top: 2rem !important;


    .sabai-row {
        width: 100%;
    }

    .sabai-form-fields {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 0 !important;

        .sabai-form-type-select {
            margin-left: auto;
            order: 2;
            margin-bottom: 0;
        }

        .sabai-form-type-fieldset {
            order: 1;
        }
    }
}

select[name="questions_tags"] {
    box-shadow: 0px 0px 4px #00000029;
    border-radius: 9px;
    border: 0;
    color: $body-color;
    background-color: $white;
    -webkit-appearance: none;
    background: url('../../../images/Down_Arrow.png') 92% center no-repeat;
    background-color: $white !important;
    background-size: 20px;
    height: 2.5rem;
    font-size: 1.563rem;
    font-weight: 400;
    padding-top: 1px;
    padding-right: 40px;
}

.post-btn,
.sabai-questions-add-answer-form .sabai-form .sabai-btn,
.sabai-questions-add-answer-form .sabai-form-type-submit:after {
    padding: .5rem 2.5rem !important;
    border-radius: 5px;
    font-size: 15px;
    text-decoration: none;
    color: $white !important;
    background-color: $primary !important;
    border-color: $primary !important;
    // &:hover {
    //     text-decoration: none;
    // }
}

.post-btn:hover {
    text-decoration: none;
}

.sabai-questions-add-answer-form .sabai-form .sabai-btn {
    line-height: 1;
    float: right;
}

.sabai-alert-danger {
    font-size: 20px;
    padding: 3px 15px;
}

.sabai-form-field-description {
    font-size: 1.25rem;
    line-height: 1.4;
}

.sabai-form-field-label {
    line-height: 1;
}

// popup
.create-post {
    h1 {
        font-size: 15px;
    }

    .modal-body {
        >p {
            margin: 0 !important;
        }
    }

    .modal-header {
        margin: 0 !important;
    }

    legend,
    .sabai-form-field-label {
        span {
            font-size: 16px;
            font-weight: 400;
        }
    }

    .modal {
        .close {
            -webkit-appearance: none;
            background-color: transparent;
            border: 0;
        }
    }
}


.post-edit-link {
    text-decoration: underline;

    &:hover {
        text-decoration: underline;
    }
}

.sabai-entity-bundle-type-questions-answers {
    .sabai-questions-activity {
        .sabai-entity-activity {
            font-size: 1.25rem;
            padding-left: 4rem !important;
        }
    }
}

.sabai-nav-tabs {
    a {
        font-size: 16px;
    }
}

.sabai-questions-main {
    .ForumTitle {
        font-size: 1.563rem;
    }

    .ForumTitle+ul li {
        font-size: 15px;
        line-height: 1;
        font-family: $primary-font;
    }

    .sabai-questions-body {
        margin-bottom: 0 !important;
    }
}

.sabai-questions-flags,
.sabai-questions-add-answer-form {
    margin-top: 10px !important;
}

#graphModal {
    .sabai-col-sm-3 {
        width: 100%;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            padding-top: 20px !important;
        }
    }
}

.sabai-questions-taxonomy a {
    font-size: 18px !important;
}


.sabai-entity-bundle-name-questions {
    .sabai-questions-status+.sabai-row {
        padding: 0 1.25rem;
        font-size: 1.25rem;
        cursor: pointer;

        &:hover {
            color: $gray-600;
        }
    }
}

.sabai-questions-featured {
    p {
        font-size: 1.25rem !important;
        line-height: 1.5rem !important;
    }
}

.sabai-form-type-file-upload,
.sabai-nav-tabs,
#sabai-inline-content-answers .sabai-questions-status {
    display: none;
}

.sabai-entity-bundle-type-questions-answers {
    padding-top: 0 !important;
    padding-bottom: 1rem !important;
    border: 0 !important;

    .sabai-questions-body {
        font-size: 15px;
        margin-top: 1rem;
    }

    .sabai-questions-activity {
        .sabai-entity-activity {
            li {
                position: relative;
                font-family: $primary-font;
                font-size: 1.5rem;
                line-height: 1;

                span {
                    font-size: 15px;
                    display: block;
                    line-height: 1;
                }

                .sabai-questions-body {
                    p {
                        font-family: $primary-font;
                    }
                }

                &:after {
                    position: absolute;
                    z-index: 1;
                    left: -2.9rem;
                    top: 6px;
                    width: 2.5rem;
                    height: 2.5rem;
                    border-radius: 50%;
                    -webkit-border-radius: 50%;
                    -moz-border-radius: 50%;
                    -ms-border-radius: 50%;
                    -o-border-radius: 50%;
                    -webkit-font-smoothing: antialiased;
                    display: inline-block;
                    font-style: normal;
                    font-variant: normal;
                    text-rendering: auto;
                    line-height: 1;
                    content: "\f007";
                    font-family: Font Awesome\ 5 Free;
                    font-weight: 900;
                    color: $primary;
                    font-size: 2rem;
                }
            }
        }
    }
}

#graphModal {
    .sabai-col-xs-6 {
        width: 100%;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

.graphImg {
    width: 25rem;
    height: 13rem;
    margin: 2rem 0;
    cursor: pointer;

    * {
        width: 100% !important;
        height: 100% !important;
    }

    img {
        object-fit: cover;
    }
}

#sabai-inline-content-answers {
    .sabai-form {
        background-color: $white;
        padding: 2rem;

        .sabai-form-buttons {
            margin-bottom: 0;
        }
    }
}

.post-edit-link {
    display: none;
}

#sabai-inline-content-answers {
    >.sabai-navigation {
        display: none;

    }

    .sabai-navigation-bottom {
        display: block !important;
        font-size: 15px;

        span {
            font-size: 15px;
        }
    }
}

.sabai-navigation-bottom {
    text-align: center;

    .sabai-pull-left,
    .sabai-pull-right {
        float: none !important;

        span {
            font-size: 15px;
        }
    }
}

.sabai-pagination {
    .sabai-btn {
        background-color: transparent !important;
        border: 0 !important;
        box-shadow: none !important;
        padding: 5px 6px !important;
    }
}

.classroom-detail {
    [download] {
        font-size: 20px;
        text-decoration: none;

        &:hover {
            .fa-download {
                color: $gray-600 !important;
            }
        }

        .fa-download {
            margin-right: 10px;
        }
    }

    p {
        font-family: $primary-font;
        line-height: 1.3;
    }
}

.modal {
    .form-cancel-link {
        display: none !important;
    }
}

.sabai-questions-add-answer-form>strong {
    font-size: 15px;
    padding-left: 3rem;
}

.go-back {
    line-height: 1;

    a {
        text-decoration: none;
    }
}

.forum-detail {
    .sabai-questions-main {
        padding-left: 2.5rem;
        padding-right: 2.5rem;
    }
    .sabai-questions-body p{
        display: flex;
        flex-wrap: wrap;
      }
}

.user-detail{
    .sabai-questions-body p{
      display: flex;
      flex-wrap: wrap;
    }
}

.sabai-content-editpost {
    .sabai-form-action {
        display: block;
    }
}

.sabai-entity-filter-form {
    .sabai-row {
        margin-left: 0;
        margin-right: 0;
    }

    .sabai-col-md-12 {
        padding-left: 0;
        padding-right: 0;
    }
}

.sabai-widget-questions-search,
.search-position {
    position: absolute;
    width: 50%;
    right: 0;
    top: 0;

    .sabai-questions-search-keyword {
        width: 100%;
    }
}

.sabai-search {
    border: 0 !important;
    padding: 0;
    background-color: transparent !important;
}

.user-detail {
    .sabai-entity {
        background-color: $white;
        padding: 1.5rem !important;
        margin-bottom: 1rem;
    }
}

.selectric-hide-select {
    opacity: 0;
}



.sabai-questions-addanswer {
    .form-cancel-link {
        float: right;
    }
}

.sabai-questions-add-answer-form {
    .sabai-form {
        .sabai-form-type-submit {
            position: relative;
            display: inline-block;
            float: right;
            min-width: 150px;

            input {
                text-indent: -9999px;
                box-shadow: none !important;
                min-width: 150px;
            }

            &:after {
                content: "Post Response";
                position: absolute;
                top: 0;
                right: 0;
                pointer-events: none;
                cursor: default;
                text-decoration: none;
                text-indent: 0;
                padding: .8rem 1rem !important;
                min-width: 150px;
                font-family: $primary-font;
                line-height: 1;
                font-weight: 700;
                box-shadow: 0 3px 6px rgba(0, 0, 0, .93);
                height: 100%;
                text-align: center;
            }
        }
    }
}

div.guteurlsBox {
    margin: 0 !important;
}

.guteurlsGU {
    display: none !important;
}

.woocommerce_account_subscriptions{
    .no_subscriptions{
        .woocommerce-Button{
            float: right !important;
        }
    }
}


.main-content{
    *:not(i) {
        font-family: $primary-font !important;
    }
}

.account-block{
    .woocommerce-Message--info{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    
}

.forum-detail{
    overflow: hidden;
    .mini-preview-anchor{
        white-space: initial;
        word-break: break-all;
    }
}

.subscription-details{
    line-height: 1.1 !important;
}