.login-form,
.woocommerce-account .intro-sec,
.woocommerce-lost-password .intro-sec {
    max-width: 615px !important;
    margin: 0 auto;
    padding: 0 15px;
}

.woocommerce-form-login__rememberme {
    margin-bottom: 0;
}

.subscription-detail {
    .woocommerce-product-gallery__image {
        height: 100%;

        >a {
            height: 100%;
            display: block;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }

    .woocommerce-tabs {
        margin-top: 2rem;
        padding: 0 15px;
    }

    .woocommerce-product-gallery__wrapper {
        margin-bottom: 0;
        height: 100%;
    }

    .tabs {
        display: none;
    }

    .quantity {
        display: none;
    }

    .woocommerce-Tabs-panel {
        h2 {
            font-size: 3.75rem;
            margin-bottom: .5rem !important;
        }
    }

    .description_tab {
        display: none;
    }

    h1 {
        line-height: 1;
    }

    .woocommerce-LoopProduct-link {
        margin-bottom: 3.5rem;
    }

    .shipping-taxable {

        .price,
        .woocommerce-loop-product__title {
            display: inline-block !important;
        }

        .wp-post-image {
            margin: 0 auto;
        }

        .woocommerce-loop-product__title {
            font-size: 2.5rem;
            padding-right: 1rem;
        }
    }

    .price {
        padding-top: 2rem;
        font-size: 4rem;
        margin-bottom: 2rem;
    }

    .product_meta {
        display: none;
    }

    .quantity {
        margin-bottom: 2.5rem;
    }

    .products {
        display: inline-block !important;
    }

}

.woocommerce-checkout {

    .shop_table {
        font-size: 1.7rem;
        width: 100%;
    }

    .place-order {
        margin-bottom: 2em;
    }

    .woocommerce-input-wrapper {
        width: 100%;
        display: block !important;
    }

    .col-1,
    .col-2 {
        max-width: 100% !important;
        flex: 1 !important;
        padding: 0;
    }

    .form-row {
        width: 100% !important;
        float: none !important;
        margin-right: 0 !important;
    }
}

.woocommerce-LostPassword {
    margin-bottom: 2em;
}

.form-row.woocommerce-invalid input.input-text {
    border-color: #e2401c;
}

.woocommerce-form-login__rememberme {
    margin-bottom: 1.5rem;
}

.woocommerce-breadcrumb {
    display: none;
}

.single_add_to_cart_button {
    float: right;
}

.select2-container--default .select2-selection--single {
    border: 0;
    height: 52px;
}

.select2-container--default .select2-results__option--highlighted[aria-selected],
.select2-container--default .select2-results__option--highlighted[data-selected] {
    background-color: $primary;
}

.woocommerce-MyAccount-navigation {
    background-color: $gray-100;
    box-shadow: 0px 0px 4px #00000029;
    border-radius: 9px;
    max-width: 615px;
    margin: 0 auto;
    display: flex;
    justify-content: center;

    ul {
        list-style-type: none;

    }
}

.select2-results__option {
    font-size: 1.7rem !important;
    line-height: 1 !important;
}

.woocommerce-billing-fields {
    p {
        margin-bottom: 2rem;
    }
}

.select2-results__option {
    &:focus {
        outline: $primary auto 5px;
    }
}

.sabai-entity-field-name-questions-tags {
    .select2-choices {
        li {
            border-radius: 4px;
            -webkit-border-radius: 4px;
            -moz-border-radius: 4px;
            -ms-border-radius: 4px;
            -o-border-radius: 4px;
            background-color: $gray-100;
            color: $body-color;
            font-size: 15px;
        }
    }
}

.sabai-questions-askquestion {
    .selectric-scroll {
        li {
            &:first-of-type {
                display: none;
            }

            &:nth-child(2) {
                &::after {
                    display: none;
                }
            }
        }
    }
}

.sabai-questions-answers {
    a {
        text-decoration: none;
    }
}

.woocommerce-ResetPassword {
    p {
        &:first-of-type {
            line-height: 1.3;
        }
    }
}

.woocommerce form .form-row .required {
    text-decoration: none;
}

.payment_method_bitpay_checkout_gateway {
    p {
        padding-left: 23px;
    }
}

.payment_method_square_credit_card {
    .woocommerce-validated {
        margin-top: 15px;
    }
}

.payment_method_square_credit_card{
    .sv-wc-payment-gateway-payment-form-manage-payment-methods{
        width: 100%;
        text-align: left !important;
    }
    .payment_method_square_credit_card{
        >.form-row {
            input{
                margin-right: 0 !important;
            }
            label{
                width: calc(100% - 25px);
            }
        }
    }
}

#order_review{
    tfoot {
        border-top: 0;
    }
}

.related{
    &.products{
    display: none !important;
    }
}