.main-rules{
    >.card,>.card >.card-body {
        background-color: transparent;
        box-shadow: none !important;
        border: 0 !important;
        padding: 0 !important;
    }
    *{
        line-height: 2.188rem;
    }
    h1{
        font-size: 2.188rem;
        font-weight: 500;
        em{
            font-weight: 400;
        }
    }
    li{
        font-size: 1.563rem;
        color: $body-color;
    }
    ul{
        padding-left: 1.5rem;
        margin-bottom: 2rem;
    }
    .card-body{
        strong{
            font-size: 1.875rem;
            color: $primary;
        }
    }
}