@charset "UTF-8";
/**
Your application specific css.
Only import here
 */
/** =============== Variables =============== **/
/* custom variable */
/* bootstrap variables */
/** =============== Header CSS =============== **/
.gradient-bg {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#060b2d+0,060e31+20,060e31+20,081641+27,081641+27,081641+32,092559+55,092559+66,092559+66,0b326e+100,070f33+100,2989d8+100,7db9e8+100 */
  background: #060b2d;
  /* Old browsers */
  background: -moz-linear-gradient(left, #060b2d 0%, #060e31 20%, #060e31 20%, #081641 27%, #081641 27%, #081641 32%, #092559 55%, #092559 66%, #092559 66%, #0b326e 100%, #070f33 100%, #2989d8 100%, #7db9e8 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #060b2d 0%, #060e31 20%, #060e31 20%, #081641 27%, #081641 27%, #081641 32%, #092559 55%, #092559 66%, #092559 66%, #0b326e 100%, #070f33 100%, #2989d8 100%, #7db9e8 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #060b2d 0%, #060e31 20%, #060e31 20%, #081641 27%, #081641 27%, #081641 32%, #092559 55%, #092559 66%, #092559 66%, #0b326e 100%, #070f33 100%, #2989d8 100%, #7db9e8 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#060b2d", endColorstr="#7db9e8", GradientType=1);
  /* IE6-9 */
}

.main-header {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#060b2d+0,060e31+20,060e31+20,081641+27,081641+27,081641+32,092559+55,092559+66,092559+66,0b326e+100,070f33+100,2989d8+100,7db9e8+100 */
  background: #060b2d;
  /* Old browsers */
  background: -moz-linear-gradient(left, #060b2d 0%, #060e31 20%, #060e31 20%, #081641 27%, #081641 27%, #081641 32%, #092559 55%, #092559 66%, #092559 66%, #0b326e 100%, #070f33 100%, #2989d8 100%, #7db9e8 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #060b2d 0%, #060e31 20%, #060e31 20%, #081641 27%, #081641 27%, #081641 32%, #092559 55%, #092559 66%, #092559 66%, #0b326e 100%, #070f33 100%, #2989d8 100%, #7db9e8 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #060b2d 0%, #060e31 20%, #060e31 20%, #081641 27%, #081641 27%, #081641 32%, #092559 55%, #092559 66%, #092559 66%, #0b326e 100%, #070f33 100%, #2989d8 100%, #7db9e8 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#060b2d", endColorstr="#7db9e8", GradientType=1);
  /* IE6-9 */
  margin-bottom: 2.5rem;
}
.main-header a {
  text-decoration: none;
}
.main-header a:hover {
  text-decoration: none;
}
.main-header.transparent-header {
  background: none;
  margin-bottom: 0;
}
.main-header .container-fluid {
  padding: 0 6vw;
}
.main-header .custom-logo-link {
  max-width: 32rem;
}
.main-header .custom-logo-link img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.main-header ul {
  padding-left: 0;
  list-style: none;
}
.main-header .menu {
  margin-bottom: 0;
  display: flex;
  align-items: center;
}
.main-header .menu li {
  padding: 1.2rem 15px;
}
.main-header .menu li a {
  font-size: 2.188rem;
  font-weight: 400;
  color: #fff;
}
.main-header .menu li:last-child {
  border-radius: 0 14px 14px 0;
  -webkit-border-radius: 14px;
  -moz-border-radius: 14px;
  -ms-border-radius: 14px;
  -o-border-radius: 14px;
  text-transform: uppercase;
  background-color: #E0B501;
  display: inline-block;
  font-family: "mr-eaves-xl-modern", sans-serif;
  color: #1c1c22;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 2px solid transparent;
  font-size: 30px !important;
  line-height: 1;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -moz-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -ms-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -o-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-14pxin-out;
  border-radius: 0 14px 14px 0;
}
.main-header .menu li:last-child:hover {
  background-color: #a88908;
}
.main-header .menu li:last-child:hover a {
  color: #fff !important;
}
.main-header .menu li:last-child a {
  color: #1c1c22;
  font-weight: 700;
  font-size: 2.5rem;
}
.main-header .menu .nav-link {
  font-family: "mr-eaves-xl-modern", sans-serif;
  color: #fff;
}

.scroll-effect {
  padding-bottom: 15px !important;
  transition: all 0.5s ease;
}

.current_page_item a {
  color: #E0B501;
}

.transparent-header {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2;
}

.navbar-collapse.show {
  left: 0;
}

.menu-open {
  left: 0 !important;
  opacity: 1 !important;
}

.trade-heading {
  line-height: 2.188rem;
  display: flex;
  align-items: center;
}
.trade-heading span {
  width: 0.6rem;
  height: 0.6rem;
  display: inline-block;
  background-color: #092258;
  border-radius: 50%;
  margin: 0 2.5rem;
}

/** =============== Main Typography =============== **/
html {
  font-size: 16px;
}

body {
  font-size: 1.875rem;
  font-weight: 400;
  line-height: 3.125rem;
  overflow-x: hidden;
  font-family: "chypre-normal", sans-serif;
  color: #092258;
}

h2 {
  font-weight: 600;
}

a {
  font-family: "mr-eaves-xl-modern", sans-serif;
  text-decoration: underline;
  display: inline-block;
  color: #092258;
}
a:hover {
  color: #E0B501 !important;
}

img {
  display: block;
}

/** =============== Global CSS =============== **/
.inner-header + main {
  margin-top: 22.5rem;
}

.stop-scrolling {
  overflow: hidden;
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0 !important;
  right: 0;
  bottom: 0;
}

article {
  position: relative;
}

.o-contain,
.o-cover {
  width: 100%;
  height: 100%;
}

.o-contain {
  object-fit: contain;
}

.o-cover {
  object-fit: cover;
}

.img-wrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  object-fit: cover;
  height: 100%;
}

p:empty {
  display: none;
}

.btn-yellow, input[type=submit] {
  background-color: #E0B501;
  color: #000;
}
.btn-yellow:hover, input[type=submit]:hover {
  color: #092258 !important;
  background-color: #E0B501 !important;
  border-color: #fff !important;
}

.btn-blue,
.woocommerce-button--previous,
.woocommerce-button--next {
  background-color: #2D0565 !important;
  color: #fff !important;
}
.btn-blue:hover,
.woocommerce-button--previous:hover,
.woocommerce-button--next:hover {
  color: #E0B501 !important;
}

.btn {
  text-transform: uppercase;
  border: 2px solid #fff;
  box-shadow: 2px 3px 6px #000;
  text-decoration: none;
}
.btn:hover {
  text-decoration: none;
}

.gray-bg {
  background-color: #1C1C22;
}

.section-divider {
  padding-top: 2.5rem;
  padding-bottom: 3rem;
}

main {
  min-height: 100vh;
}

.astrike-styling {
  position: absolute;
  color: #E01A1A;
  top: 24px;
  left: 0;
  font-size: 30px;
}

.modal-dialog {
  max-width: 75vw;
  margin: 30px auto;
  padding: 0 15px;
}
.modal-dialog .modal-content {
  border: 1px solid #707070;
  border-radius: 0;
  padding: 1.2rem;
  height: 100%;
}
.modal-dialog .modal-content .modal-header {
  border-bottom: 1px solid #7e7e7e;
}
.modal-dialog .modal-content .modal-header h1 {
  margin-bottom: 0;
  font-weight: 700;
}
.modal-dialog .modal-content .close {
  margin: 0;
  font-size: 15px;
  box-shadow: none;
  color: #ccc;
  line-height: 1;
  z-index: 2;
  padding: 0;
  -webkit-appearance: none;
  border: 0;
  text-transform: uppercase;
  margin-left: auto;
  background-color: transparent;
}
.modal-dialog .modal-content .close:focus {
  outline: none;
}

.woocommerce-message,
.woocommerce-info,
.woocommerce-error,
.woocommerce-noreviews,
p.no-comments {
  background-color: transparent !important;
  padding-left: 0;
  line-height: 1.2 !important;
  font-size: 18px !important;
  width: 100% !important;
}

.post-pagination .page-numbers {
  list-style-type: none;
  display: flex;
  padding-left: 0;
  justify-content: center;
  font-size: 15px;
  align-items: center;
}
.post-pagination .page-numbers li {
  margin: 0 2px;
  display: flex;
  font-family: "mr-eaves-xl-modern", sans-serif;
}
.post-pagination .page-numbers li a {
  text-decoration: none;
}

td:empty, th:empty {
  display: none;
}

.card {
  border: 0 !important;
}

/** =============== Homepage CSS =============== **/
.price-sec * {
  font-family: "mr-eaves-xl-modern", sans-serif;
}

.banner-content {
  z-index: 1;
  padding-bottom: 13vh;
  padding-top: 21rem;
}
.banner-content h5 {
  margin-bottom: 3.5rem !important;
}
.banner-content h1 {
  font-size: 6.25rem;
}
.banner-content h1,
.banner-content h5 {
  text-shadow: 0px 3px 6px #00000064;
}
.banner-content * {
  font-family: "mr-eaves-xl-modern", sans-serif;
}
.banner-content p {
  line-height: 2.25rem;
  text-shadow: 0px 3px 6px #000000;
}

.join-btn {
  margin-top: 5rem;
}

.price {
  font-size: 11.56rem;
  display: block;
  padding-top: 5rem;
}

.join-now {
  padding-left: 9rem;
  padding-right: 8rem;
  margin-top: 5rem;
}

.join-now + span {
  font-size: 1.563rem;
  font-family: "mr-eaves-xl-modern", sans-serif;
  line-height: 1;
}
.join-now + span a {
  font-size: 1.563rem;
  color: #fff;
}
.join-now + span a:hover {
  border-color: #E0B501;
}

.btn-links .btn {
  border: 5px solid #fff;
  box-shadow: none;
  font-family: "mr-eaves-xl-modern", sans-serif;
  cursor: auto !important;
}
.btn-links .col-md:nth-of-type(2) {
  background-color: #303F7C;
}
.btn-links .col-md:nth-of-type(3) {
  background-color: #404a72;
}

.trading-sec {
  background: -moz-linear-gradient(left, white 0%, rgba(255, 255, 255, 0.37) 63%, rgba(255, 255, 255, 0) 100%);
  background: -webkit-linear-gradient(left, white 0%, rgba(255, 255, 255, 0.37) 63%, rgba(255, 255, 255, 0) 100%);
  background: linear-gradient(to right, white 0%, rgba(255, 255, 255, 0.9) 62%, rgba(255, 255, 255, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff", endColorstr="#00ffffff", GradientType=1);
}
.trading-sec p {
  font-size: 1.5rem;
  line-height: 2.5rem;
}
.trading-sec .img-wrap {
  width: 51%;
  right: 0;
  left: auto;
  z-index: -1;
}
.trading-sec .img-wrap > p {
  object-position: top;
  font-size: 1.5rem;
  line-height: 2.5rem;
}

.free-list li {
  font-size: 2.1rem;
  margin-bottom: 5px;
  font-family: "mr-eaves-xl-modern", sans-serif;
  font-weight: 700;
}

.trading-info-sec .font-italic {
  font-weight: 500 !important;
}

.join-now a,
.join-now-2 a {
  padding-left: 4rem;
  padding-right: 4rem;
}

.social-links a, .social-links a .fa {
  color: #fff !important;
  font-size: 22px;
  padding: 2px;
}
.social-links a:hover, .social-links a .fa:hover {
  color: #E0B501 !important;
}

.side-bar {
  width: 300px;
  padding: 2.5rem 0;
}
.side-bar h2 {
  font-family: "mr-eaves-xl-modern", sans-serif;
}
.side-bar .icon-bar {
  height: 4px;
  background-color: #092258;
  display: -ms-inline-flexbox;
  display: block;
  border-radius: 2px;
  transition: 0.25s ease;
  margin-bottom: 4px;
  -webkit-transition: 0.25s ease;
  -moz-transition: 0.25s ease;
  -ms-transition: 0.25s ease;
  -o-transition: 0.25s ease;
}
.side-bar li a {
  display: block;
  padding: 15px;
  font-size: 1.7rem;
  letter-spacing: 1px;
  line-height: 1;
  color: #fff !important;
  text-decoration: none;
}
.side-bar li a:hover, .side-bar li a.active {
  background-color: #000;
  text-decoration: none;
  color: #E0B501 !important;
}

.main-content {
  width: 100%;
  min-height: 100vh;
}
.main-content .embed-responsive {
  box-shadow: 0px 0px 4px #00000029;
  border-radius: 0.5625rem;
}
.main-content .container {
  padding: 6rem;
}
.main-content .container .card {
  box-shadow: 0px 0px 4px #00000029;
}
.main-content h1 {
  font-weight: 500;
  margin-bottom: 2rem;
}
.main-content .card-body a {
  text-decoration: none;
}
.main-content .card-body a:hover {
  text-decoration: none;
}
.main-content .card-body img {
  width: 6rem;
  height: 6rem;
}

.logo-img {
  width: 16rem;
  margin: 0 auto;
  height: 11rem;
}

.details-wrap {
  background-color: #556A96;
  padding: 1rem 1.3rem;
}
.details-wrap span {
  font-family: "mr-eaves-xl-modern", sans-serif;
}
.details-wrap span:nth-child(1) {
  font-size: 15px;
  line-height: 20px;
}
.details-wrap span:nth-child(2) {
  font-size: 1.563rem;
}
.details-wrap h2 {
  font-size: 2.1rem;
}

.level-trading {
  margin-right: -7px;
  margin-left: -7px;
}
.level-trading a {
  text-decoration: none;
}
.level-trading a:hover {
  text-decoration: none;
}
.level-trading .card {
  min-height: 20rem;
  margin-right: -7px;
  margin-left: -7px;
}
.level-trading .card [class*=col] {
  padding-left: 7px;
  padding-right: 7px;
}

.main-info h1,
.main-info h2,
.main-info h3,
.main-info h4,
.main-info h5,
.main-info h6,
.sabai-main h1,
.sabai-main h2,
.sabai-main h3,
.sabai-main h4,
.sabai-main h5,
.sabai-main h6 {
  font-weight: 500;
}
.main-info .card-body p,
.sabai-main .card-body p {
  color: #404040;
  font-size: 1.563rem;
  line-height: 2.188rem;
  font-weight: 500;
}
.main-info .card-body p:last-child,
.sabai-main .card-body p:last-child {
  margin-bottom: 0;
}

.center-line {
  position: relative;
}
.center-line span {
  padding-right: 15px;
}
.center-line::before {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 15px;
  width: calc(100% - 15px);
  background-color: #092258;
  height: 3px;
  content: "";
  z-index: 0;
}

.trading-list .card {
  box-shadow: none;
  text-decoration: none;
}
.trading-list .card .card-body {
  box-shadow: none;
  text-decoration: none;
}
.trading-list .card .card-body p {
  font-size: 1.25rem;
  line-height: 1.5rem;
  margin-bottom: 0;
}

.circle-ui {
  width: 3rem;
  height: 3rem;
  border: 2px solid #092258;
}
.circle-ui.filled {
  background-color: #092258;
}

.circle-ui + .card-body {
  width: calc(100% - 3rem);
  padding-left: 1rem !important;
}
.circle-ui + .card-body h6 {
  line-height: 1;
}

.mini-preview-anchor {
  width: 100%;
  margin-top: 3rem;
}

.mini-preview-wrapper {
  position: static !important;
  opacity: 1 !important;
  width: 100% !important;
  margin-top: -55px !important;
  height: 470px !important;
  overflow: visible !important;
  border: 0 !important;
  box-shadow: none !important;
}

.sabai-form-field-error input,
.sabai-form-field-error select,
.sabai-form-field-error textarea {
  outline: none !important;
}

/** =============== Price action CSS =============== **/
.intro-sec {
  margin-bottom: 3.5rem;
}

.join-now-2 {
  margin-top: 4rem;
}

.selectric-wrapper {
  position: relative;
  cursor: pointer;
}

.selectric-responsive {
  width: 100%;
}

.selectric {
  border: 1px solid #DDD;
  border-radius: 0px;
  background: #F8F8F8;
  position: relative;
  overflow: hidden;
}
.selectric .label {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 38px 0 10px;
  font-size: 12px;
  line-height: 38px;
  color: #444;
  height: 38px;
  user-select: none;
}
.selectric .button {
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  width: 38px;
  height: 38px;
  line-height: 38px;
  background-color: #F8f8f8;
  color: #BBB;
  text-align: center;
  font: 0/0 a;
  *font: 20px/38px Lucida Sans Unicode, Arial Unicode MS, Arial;
}
.selectric .button:after {
  content: " ";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 0;
  height: 0;
  border: 4px solid transparent;
  border-top-color: #BBB;
  border-bottom: none;
}

.selectric-focus .selectric {
  border-color: #aaaaaa;
}

.selectric-hover .selectric {
  border-color: #c4c4c4;
}
.selectric-hover .selectric .button {
  color: #a2a2a2;
}
.selectric-hover .selectric .button:after {
  border-top-color: #a2a2a2;
}

.selectric-open {
  z-index: 9999;
}
.selectric-open .selectric {
  border-color: #c4c4c4;
}
.selectric-open .selectric-items {
  display: block;
}

.selectric-disabled {
  filter: alpha(opacity=50);
  opacity: 0.5;
  cursor: default;
  user-select: none;
}

.selectric-hide-select {
  position: relative;
  overflow: hidden;
  width: 0;
  height: 0;
}
.selectric-hide-select select {
  position: absolute;
  left: -100%;
}
.selectric-hide-select.selectric-is-native {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
}
.selectric-hide-select.selectric-is-native select {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  border: none;
  z-index: 1;
  box-sizing: border-box;
  opacity: 0;
}

.selectric-input {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  margin: 0 !important;
  padding: 0 !important;
  width: 1px !important;
  height: 1px !important;
  outline: none !important;
  border: none !important;
  *font: 0/0 a !important;
  background: none !important;
}

.selectric-temp-show {
  position: absolute !important;
  visibility: hidden !important;
  display: block !important;
}

/* Items box */
.selectric-items {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background: #F8F8F8;
  border: 1px solid #c4c4c4;
  z-index: -1;
  box-shadow: 0 0 10px -6px;
}
.selectric-items .selectric-scroll {
  height: 100%;
  overflow: auto;
}
.selectric-above .selectric-items {
  top: auto;
  bottom: 100%;
}
.selectric-items ul, .selectric-items li {
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 12px;
  line-height: 20px;
  min-height: 20px;
}
.selectric-items li {
  display: block;
  padding: 10px;
  color: #666;
  cursor: pointer;
}
.selectric-items li.selected {
  background: #E0E0E0;
  color: #444;
}
.selectric-items li.highlighted {
  background: #D0D0D0;
  color: #444;
}
.selectric-items li:hover {
  background: #D5D5D5;
  color: #444;
}
.selectric-items .disabled {
  filter: alpha(opacity=50);
  opacity: 0.5;
  cursor: default !important;
  background: none !important;
  color: #666 !important;
  user-select: none;
}
.selectric-items .selectric-group .selectric-group-label {
  font-weight: bold;
  padding-left: 10px;
  cursor: default;
  user-select: none;
  background: none;
  color: #444;
}
.selectric-items .selectric-group.disabled li {
  filter: alpha(opacity=100);
  opacity: 1;
}
.selectric-items .selectric-group li {
  padding-left: 25px;
}

.terms-condition p, .terms-condition h4 {
  font-size: 20px;
  line-height: 35px;
}

.main-rules > .card, .main-rules > .card > .card-body {
  background-color: transparent;
  box-shadow: none !important;
  border: 0 !important;
  padding: 0 !important;
}
.main-rules * {
  line-height: 2.188rem;
}
.main-rules h1 {
  font-size: 2.188rem;
  font-weight: 500;
}
.main-rules h1 em {
  font-weight: 400;
}
.main-rules li {
  font-size: 1.563rem;
  color: #404040;
}
.main-rules ul {
  padding-left: 1.5rem;
  margin-bottom: 2rem;
}
.main-rules .card-body strong {
  font-size: 1.875rem;
  color: #092258;
}

.widget ul {
  padding-left: 0;
  list-style: none;
}
.widget ul a {
  color: #092258;
}

.sabai-questions-questions {
  padding: 0;
}
.sabai-questions-questions .sabai-questions-noanswers {
  background-color: #fff !important;
}
.sabai-questions-questions .center-line {
  padding-left: 2rem;
}
.sabai-questions-questions .center-line:before {
  left: 2rem;
}
.sabai-questions-questions .center-line span {
  font-size: 1.875rem;
}
.sabai-questions-questions .row {
  margin: 5px 0 0 !important;
  border: 0 !important;
  width: 100% !important;
  padding: 1.5rem 0 !important;
  background-color: #fff !important;
}
.sabai-questions-questions .row:before {
  display: none !important;
}
.sabai-questions-questions .row a {
  text-decoration: none;
}
.sabai-questions-questions .row .col-4 a {
  font-size: 1.563rem;
  font-family: chypre-normal;
  font-weight: 500;
}
.sabai-questions-questions .row.sabai-questions-featured {
  background-color: #E7EBF2;
}
.sabai-questions-questions .row.sabai-questions-featured div {
  font-size: 15px;
}
.sabai-questions-questions .row div {
  font-size: 15px;
  font-family: "mr-eaves-xl-modern", sans-serif;
  color: #092258;
  line-height: 1;
}

.sabai-questions-title a {
  color: #092258;
}

.sabai-number {
  color: #092258;
}

.sabai-pull-right a {
  line-height: 1;
}

.listing-wrap {
  margin-bottom: 5rem;
}

.selectric {
  border: 0;
}
.selectric span,
.selectric b {
  display: none !important;
}

.selectric-items {
  position: static;
  border: 0;
  display: inline-block;
}
.selectric-items .selectric-scroll ul {
  display: flex;
  background-color: #fff;
  box-shadow: 0px 0px 4px #00000029;
  border-radius: 0.5625rem;
  -webkit-border-radius: 0.5625rem;
  -moz-border-radius: 0.5625rem;
  -ms-border-radius: 0.5625rem;
  -o-border-radius: 0.5625rem;
}
.selectric-items .selectric-scroll ul li {
  background-color: transparent;
  font-size: 1.563rem;
  color: #404040;
  font-family: "mr-eaves-xl-modern", sans-serif;
  text-transform: uppercase;
  position: relative;
  white-space: nowrap;
}
.selectric-items .selectric-scroll ul li:first-of-type {
  display: none;
}
.selectric-items .selectric-scroll ul li:hover, .selectric-items .selectric-scroll ul li.selected {
  color: #E0B501;
}
.selectric-items .selectric-scroll ul li:nth-child(2)::after {
  display: none;
}
.selectric-items .selectric-scroll ul li:after {
  content: "";
  position: absolute;
  top: 10px;
  left: 0;
  height: calc(100% - 20px);
  width: 2px;
  background-color: #404040;
}

.sabai-form.sabai-entity-filter-form .selectric-items .selectric-scroll ul li:first-of-type {
  display: block !important;
}
.sabai-form.sabai-entity-filter-form .selectric-items .selectric-scroll ul li:hover {
  color: #E0B501;
}
.sabai-form.sabai-entity-filter-form .selectric-items .selectric-scroll ul li:nth-child(2)::after {
  display: block !important;
}
.sabai-form.sabai-entity-filter-form .selectric-items .selectric-scroll ul li:nth-child(1)::after {
  display: none !important;
}

.sabai-entity-label-featured {
  background-color: #092258;
}

.sabai-user-with-thumbnail {
  background: none !important;
  padding-left: 0 !important;
  word-break: break-all;
  line-height: 1;
  color: #092258;
}
.sabai-user-with-thumbnail a {
  text-decoration: none;
}
.sabai-user-with-thumbnail a:hover {
  text-decoration: none;
}

.sabai-pull-right {
  line-height: 1;
}

.sabai-questions-featured .sabai-entity-links {
  opacity: 1;
  visibility: initial;
}
.sabai-questions-featured .sabai-row {
  margin-left: 0;
  margin-right: 0;
}
.sabai-questions-featured h2 {
  font-size: 1.875rem;
}
.sabai-questions-featured p {
  font-size: 1.25rem;
  line-height: 1.5rem;
}
.sabai-questions-featured .sabai-questions-body div {
  margin-bottom: 2rem;
}

.sabai-questions-comments a,
.sabai-questions-taxonomy a,
.sabai-entity-activity a,
.sabai-questions-add-answer-form a,
.sabai-system-user-activity a {
  color: #092258;
}

.sabai-entity-filter-form {
  margin-top: 2rem !important;
}
.sabai-entity-filter-form .sabai-row {
  width: 100%;
}
.sabai-entity-filter-form .sabai-form-fields {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0 !important;
}
.sabai-entity-filter-form .sabai-form-fields .sabai-form-type-select {
  margin-left: auto;
  order: 2;
  margin-bottom: 0;
}
.sabai-entity-filter-form .sabai-form-fields .sabai-form-type-fieldset {
  order: 1;
}

select[name=questions_tags] {
  box-shadow: 0px 0px 4px #00000029;
  border-radius: 9px;
  border: 0;
  color: #404040;
  background-color: #fff;
  -webkit-appearance: none;
  background: url("../../../images/Down_Arrow.png") 92% center no-repeat;
  background-color: #fff !important;
  background-size: 20px;
  height: 2.5rem;
  font-size: 1.563rem;
  font-weight: 400;
  padding-top: 1px;
  padding-right: 40px;
}

.post-btn,
.sabai-questions-add-answer-form .sabai-form .sabai-btn,
.sabai-questions-add-answer-form .sabai-form-type-submit:after {
  padding: 0.5rem 2.5rem !important;
  border-radius: 5px;
  font-size: 15px;
  text-decoration: none;
  color: #fff !important;
  background-color: #092258 !important;
  border-color: #092258 !important;
}

.post-btn:hover {
  text-decoration: none;
}

.sabai-questions-add-answer-form .sabai-form .sabai-btn {
  line-height: 1;
  float: right;
}

.sabai-alert-danger {
  font-size: 20px;
  padding: 3px 15px;
}

.sabai-form-field-description {
  font-size: 1.25rem;
  line-height: 1.4;
}

.sabai-form-field-label {
  line-height: 1;
}

.create-post h1 {
  font-size: 15px;
}
.create-post .modal-body > p {
  margin: 0 !important;
}
.create-post .modal-header {
  margin: 0 !important;
}
.create-post legend span,
.create-post .sabai-form-field-label span {
  font-size: 16px;
  font-weight: 400;
}
.create-post .modal .close {
  -webkit-appearance: none;
  background-color: transparent;
  border: 0;
}

.post-edit-link {
  text-decoration: underline;
}
.post-edit-link:hover {
  text-decoration: underline;
}

.sabai-entity-bundle-type-questions-answers .sabai-questions-activity .sabai-entity-activity {
  font-size: 1.25rem;
  padding-left: 4rem !important;
}

.sabai-nav-tabs a {
  font-size: 16px;
}

.sabai-questions-main .ForumTitle {
  font-size: 1.563rem;
}
.sabai-questions-main .ForumTitle + ul li {
  font-size: 15px;
  line-height: 1;
  font-family: "mr-eaves-xl-modern", sans-serif;
}
.sabai-questions-main .sabai-questions-body {
  margin-bottom: 0 !important;
}

.sabai-questions-flags,
.sabai-questions-add-answer-form {
  margin-top: 10px !important;
}

#graphModal .sabai-col-sm-3 {
  width: 100%;
}
#graphModal .sabai-col-sm-3 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  padding-top: 20px !important;
}

.sabai-questions-taxonomy a {
  font-size: 18px !important;
}

.sabai-entity-bundle-name-questions .sabai-questions-status + .sabai-row {
  padding: 0 1.25rem;
  font-size: 1.25rem;
  cursor: pointer;
}
.sabai-entity-bundle-name-questions .sabai-questions-status + .sabai-row:hover {
  color: #E0B501;
}

.sabai-questions-featured p {
  font-size: 1.25rem !important;
  line-height: 1.5rem !important;
}

.sabai-form-type-file-upload,
.sabai-nav-tabs,
#sabai-inline-content-answers .sabai-questions-status {
  display: none;
}

.sabai-entity-bundle-type-questions-answers {
  padding-top: 0 !important;
  padding-bottom: 1rem !important;
  border: 0 !important;
}
.sabai-entity-bundle-type-questions-answers .sabai-questions-body {
  font-size: 15px;
  margin-top: 1rem;
}
.sabai-entity-bundle-type-questions-answers .sabai-questions-activity .sabai-entity-activity li {
  position: relative;
  font-family: "mr-eaves-xl-modern", sans-serif;
  font-size: 1.5rem;
  line-height: 1;
}
.sabai-entity-bundle-type-questions-answers .sabai-questions-activity .sabai-entity-activity li span {
  font-size: 15px;
  display: block;
  line-height: 1;
}
.sabai-entity-bundle-type-questions-answers .sabai-questions-activity .sabai-entity-activity li .sabai-questions-body p {
  font-family: "mr-eaves-xl-modern", sans-serif;
}
.sabai-entity-bundle-type-questions-answers .sabai-questions-activity .sabai-entity-activity li:after {
  position: absolute;
  z-index: 1;
  left: -2.9rem;
  top: 6px;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  content: "";
  font-family: Font Awesome\ 5 Free;
  font-weight: 900;
  color: #092258;
  font-size: 2rem;
}

#graphModal .sabai-col-xs-6 {
  width: 100%;
}
#graphModal .sabai-col-xs-6 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.graphImg {
  width: 25rem;
  height: 13rem;
  margin: 2rem 0;
  cursor: pointer;
}
.graphImg * {
  width: 100% !important;
  height: 100% !important;
}
.graphImg img {
  object-fit: cover;
}

#sabai-inline-content-answers .sabai-form {
  background-color: #fff;
  padding: 2rem;
}
#sabai-inline-content-answers .sabai-form .sabai-form-buttons {
  margin-bottom: 0;
}

.post-edit-link {
  display: none;
}

#sabai-inline-content-answers > .sabai-navigation {
  display: none;
}
#sabai-inline-content-answers .sabai-navigation-bottom {
  display: block !important;
  font-size: 15px;
}
#sabai-inline-content-answers .sabai-navigation-bottom span {
  font-size: 15px;
}

.sabai-navigation-bottom {
  text-align: center;
}
.sabai-navigation-bottom .sabai-pull-left,
.sabai-navigation-bottom .sabai-pull-right {
  float: none !important;
}
.sabai-navigation-bottom .sabai-pull-left span,
.sabai-navigation-bottom .sabai-pull-right span {
  font-size: 15px;
}

.sabai-pagination .sabai-btn {
  background-color: transparent !important;
  border: 0 !important;
  box-shadow: none !important;
  padding: 5px 6px !important;
}

.classroom-detail [download] {
  font-size: 20px;
  text-decoration: none;
}
.classroom-detail [download]:hover .fa-download {
  color: #E0B501 !important;
}
.classroom-detail [download] .fa-download {
  margin-right: 10px;
}
.classroom-detail p {
  font-family: "mr-eaves-xl-modern", sans-serif;
  line-height: 1.3;
}

.modal .form-cancel-link {
  display: none !important;
}

.sabai-questions-add-answer-form > strong {
  font-size: 15px;
  padding-left: 3rem;
}

.go-back {
  line-height: 1;
}
.go-back a {
  text-decoration: none;
}

.forum-detail .sabai-questions-main {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}
.forum-detail .sabai-questions-body p {
  display: flex;
  flex-wrap: wrap;
}

.user-detail .sabai-questions-body p {
  display: flex;
  flex-wrap: wrap;
}

.sabai-content-editpost .sabai-form-action {
  display: block;
}

.sabai-entity-filter-form .sabai-row {
  margin-left: 0;
  margin-right: 0;
}
.sabai-entity-filter-form .sabai-col-md-12 {
  padding-left: 0;
  padding-right: 0;
}

.sabai-widget-questions-search,
.search-position {
  position: absolute;
  width: 50%;
  right: 0;
  top: 0;
}
.sabai-widget-questions-search .sabai-questions-search-keyword,
.search-position .sabai-questions-search-keyword {
  width: 100%;
}

.sabai-search {
  border: 0 !important;
  padding: 0;
  background-color: transparent !important;
}

.user-detail .sabai-entity {
  background-color: #fff;
  padding: 1.5rem !important;
  margin-bottom: 1rem;
}

.selectric-hide-select {
  opacity: 0;
}

.sabai-questions-addanswer .form-cancel-link {
  float: right;
}

.sabai-questions-add-answer-form .sabai-form .sabai-form-type-submit {
  position: relative;
  display: inline-block;
  float: right;
  min-width: 150px;
}
.sabai-questions-add-answer-form .sabai-form .sabai-form-type-submit input {
  text-indent: -9999px;
  box-shadow: none !important;
  min-width: 150px;
}
.sabai-questions-add-answer-form .sabai-form .sabai-form-type-submit:after {
  content: "Post Response";
  position: absolute;
  top: 0;
  right: 0;
  pointer-events: none;
  cursor: default;
  text-decoration: none;
  text-indent: 0;
  padding: 0.8rem 1rem !important;
  min-width: 150px;
  font-family: "mr-eaves-xl-modern", sans-serif;
  line-height: 1;
  font-weight: 700;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.93);
  height: 100%;
  text-align: center;
}

div.guteurlsBox {
  margin: 0 !important;
}

.guteurlsGU {
  display: none !important;
}

.woocommerce_account_subscriptions .no_subscriptions .woocommerce-Button {
  float: right !important;
}

.main-content *:not(i) {
  font-family: "mr-eaves-xl-modern", sans-serif !important;
}

.account-block .woocommerce-Message--info {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.forum-detail {
  overflow: hidden;
}
.forum-detail .mini-preview-anchor {
  white-space: initial;
  word-break: break-all;
}

.subscription-details {
  line-height: 1.1 !important;
}

.login-form,
.woocommerce-account .intro-sec,
.woocommerce-lost-password .intro-sec {
  max-width: 615px !important;
  margin: 0 auto;
  padding: 0 15px;
}

.woocommerce-form-login__rememberme {
  margin-bottom: 0;
}

.subscription-detail .woocommerce-product-gallery__image {
  height: 100%;
}
.subscription-detail .woocommerce-product-gallery__image > a {
  height: 100%;
  display: block;
}
.subscription-detail .woocommerce-product-gallery__image > a img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.subscription-detail .woocommerce-tabs {
  margin-top: 2rem;
  padding: 0 15px;
}
.subscription-detail .woocommerce-product-gallery__wrapper {
  margin-bottom: 0;
  height: 100%;
}
.subscription-detail .tabs {
  display: none;
}
.subscription-detail .quantity {
  display: none;
}
.subscription-detail .woocommerce-Tabs-panel h2 {
  font-size: 3.75rem;
  margin-bottom: 0.5rem !important;
}
.subscription-detail .description_tab {
  display: none;
}
.subscription-detail h1 {
  line-height: 1;
}
.subscription-detail .woocommerce-LoopProduct-link {
  margin-bottom: 3.5rem;
}
.subscription-detail .shipping-taxable .price,
.subscription-detail .shipping-taxable .woocommerce-loop-product__title {
  display: inline-block !important;
}
.subscription-detail .shipping-taxable .wp-post-image {
  margin: 0 auto;
}
.subscription-detail .shipping-taxable .woocommerce-loop-product__title {
  font-size: 2.5rem;
  padding-right: 1rem;
}
.subscription-detail .price {
  padding-top: 2rem;
  font-size: 4rem;
  margin-bottom: 2rem;
}
.subscription-detail .product_meta {
  display: none;
}
.subscription-detail .quantity {
  margin-bottom: 2.5rem;
}
.subscription-detail .products {
  display: inline-block !important;
}

.woocommerce-checkout .shop_table {
  font-size: 1.7rem;
  width: 100%;
}
.woocommerce-checkout .place-order {
  margin-bottom: 2em;
}
.woocommerce-checkout .woocommerce-input-wrapper {
  width: 100%;
  display: block !important;
}
.woocommerce-checkout .col-1,
.woocommerce-checkout .col-2 {
  max-width: 100% !important;
  flex: 1 !important;
  padding: 0;
}
.woocommerce-checkout .form-row {
  width: 100% !important;
  float: none !important;
  margin-right: 0 !important;
}

.woocommerce-LostPassword {
  margin-bottom: 2em;
}

.form-row.woocommerce-invalid input.input-text {
  border-color: #e2401c;
}

.woocommerce-form-login__rememberme {
  margin-bottom: 1.5rem;
}

.woocommerce-breadcrumb {
  display: none;
}

.single_add_to_cart_button {
  float: right;
}

.select2-container--default .select2-selection--single {
  border: 0;
  height: 52px;
}

.select2-container--default .select2-results__option--highlighted[aria-selected],
.select2-container--default .select2-results__option--highlighted[data-selected] {
  background-color: #092258;
}

.woocommerce-MyAccount-navigation {
  background-color: #f5f5f5;
  box-shadow: 0px 0px 4px #00000029;
  border-radius: 9px;
  max-width: 615px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}
.woocommerce-MyAccount-navigation ul {
  list-style-type: none;
}

.select2-results__option {
  font-size: 1.7rem !important;
  line-height: 1 !important;
}

.woocommerce-billing-fields p {
  margin-bottom: 2rem;
}

.select2-results__option:focus {
  outline: #092258 auto 5px;
}

.sabai-entity-field-name-questions-tags .select2-choices li {
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  background-color: #f5f5f5;
  color: #404040;
  font-size: 15px;
}

.sabai-questions-askquestion .selectric-scroll li:first-of-type {
  display: none;
}
.sabai-questions-askquestion .selectric-scroll li:nth-child(2)::after {
  display: none;
}

.sabai-questions-answers a {
  text-decoration: none;
}

.woocommerce-ResetPassword p:first-of-type {
  line-height: 1.3;
}

.woocommerce form .form-row .required {
  text-decoration: none;
}

.payment_method_bitpay_checkout_gateway p {
  padding-left: 23px;
}

.payment_method_square_credit_card .woocommerce-validated {
  margin-top: 15px;
}

.payment_method_square_credit_card .sv-wc-payment-gateway-payment-form-manage-payment-methods {
  width: 100%;
  text-align: left !important;
}
.payment_method_square_credit_card .payment_method_square_credit_card > .form-row input {
  margin-right: 0 !important;
}
.payment_method_square_credit_card .payment_method_square_credit_card > .form-row label {
  width: calc(100% - 25px);
}

#order_review tfoot {
  border-top: 0;
}

.related.products {
  display: none !important;
}

/* Trading recording css */
.trading-recordings .trading-list {
  padding: 0 15px;
}
.trading-recordings .trading-list a:hover {
  color: #092258 !important;
}
.trading-recordings h6 {
  line-height: 1;
}
.trading-recordings .card-body {
  padding-right: 2rem !important;
}
.trading-recordings a .col-md-3 p {
  font-family: "chypre-normal", sans-serif;
  font-weight: 700;
  line-height: 1;
}

.recordings-title .col-md-3 {
  padding-left: 0 !important;
}
.recordings-title [class*=col] {
  font-size: 15px;
  font-family: "mr-eaves-xl-modern", sans-serif;
}

.nav-tabs .nav-link {
  padding: 1rem !important;
  margin-bottom: 10px;
  line-height: 1;
  text-align: center;
  background-color: #092258 !important;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  font-family: chypre-normal;
  font-weight: 500;
  font-size: 1.875rem;
  line-height: 1;
  background-color: #e7ebf2;
  border-radius: 0.625rem;
  -webkit-border-radius: 0.625rem;
  color: #fff;
  text-decoration: none;
  border: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.nav-tabs .nav-link.active {
  color: #E0B501;
}

/** =============== Responsive CSS =============== **/
/** ==== Based on max-width ==== **/
@media (max-width: 1550px) {
  .cart .note-block,
.cart .single_add_to_cart_button,
.cart .product_type_simple.add_to_cart_button,
.singleAddtoCart {
    font-size: 1.5rem;
  }
}
@media (max-width: 1450px) {
  html {
    font-size: 13px;
  }
}
@media (max-width: 1024px) {
  html {
    font-size: 11px;
  }

  .banner {
    min-height: auto !important;
  }

  .btn-links .btn {
    font-size: 2rem;
  }
}
@media (max-width: 991px) {
  /* header */
  .main-header .menu {
    padding-top: 45px;
    display: block;
  }
  .main-header .menu li {
    margin-bottom: 20px;
  }
  .main-header .menu li a {
    font-size: 35px;
  }
  .main-header .menu li:last-child {
    border-radius: 14px;
    -webkit-border-radius: 14px;
    -moz-border-radius: 14px;
    -ms-border-radius: 14px;
    -o-border-radius: 14px;
  }
  .main-header .menu li:last-child a {
    font-size: 35px;
  }
  .main-header .navbar-collapse {
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#060b2d+0,060e31+20,060e31+20,081641+27,081641+27,081641+32,092559+55,092559+66,092559+66,0b326e+100,070f33+100,2989d8+100,7db9e8+100 */
    background: #060b2d;
    /* Old browsers */
    background: -moz-linear-gradient(top, #060b2d 0%, #060e31 20%, #060e31 20%, #081641 27%, #081641 27%, #081641 32%, #092559 55%, #092559 66%, #092559 66%, #0b326e 100%, #070f33 100%, #2989d8 100%, #7db9e8 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #060b2d 0%, #060e31 20%, #060e31 20%, #081641 27%, #081641 27%, #081641 32%, #092559 55%, #092559 66%, #092559 66%, #0b326e 100%, #070f33 100%, #2989d8 100%, #7db9e8 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #060b2d 0%, #060e31 20%, #060e31 20%, #081641 27%, #081641 27%, #081641 32%, #092559 55%, #092559 66%, #092559 66%, #0b326e 100%, #070f33 100%, #2989d8 100%, #7db9e8 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#060b2d", endColorstr="#7db9e8", GradientType=0);
    /* IE6-9 */
  }
  .main-header .navbar-nav + .btn {
    border-radius: 14px;
    margin-top: 20px;
  }
  .main-header .nav-link {
    font-size: 25px;
  }

  .navbar-toggler[aria-expanded=true] .icon-bar {
    position: relative;
  }
  .navbar-toggler[aria-expanded=true] .icon-bar:nth-child(1) {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    top: 3px;
    left: -3px;
  }
  .navbar-toggler[aria-expanded=true] .icon-bar:nth-child(2) {
    display: none;
  }
  .navbar-toggler[aria-expanded=true] .icon-bar:nth-child(3) {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    top: -5px;
    left: -3px;
  }

  .navbar-toggler {
    width: 55px;
    outline: none;
    padding: 1.2rem 0.75rem;
    z-index: 3;
    border: 0;
  }
  .navbar-toggler:focus {
    outline: none;
  }
  .navbar-toggler .icon-bar {
    height: 4px;
    background-color: #E0B501;
    display: -ms-inline-flexbox;
    display: block;
    border-radius: 2px;
    transition: 0.25s linear;
    -webkit-transition: 0.25s linear;
    -moz-transition: 0.25s linear;
    -ms-transition: 0.25s linear;
    -o-transition: 0.25s linear;
    margin-bottom: 4px;
  }

  .navbar-collapse {
    position: fixed;
    left: -100%;
    width: 100vw;
    background-color: #092258;
    height: 100vh !important;
    transition: left 0.5s ease;
    top: 0;
    display: block !important;
    opacity: 0;
    padding: 25px;
    overflow: auto;
    z-index: 2;
  }
  .navbar-collapse.show {
    left: 0 !important;
    opacity: 1 !important;
  }

  .menu-button {
    position: absolute;
    right: 15px;
    margin-top: 1.2rem;
  }
  .menu-button[aria-expanded=true] .icon-bar {
    background-color: #fff;
  }
  .menu-button .icon-bar {
    background-color: #092258;
  }

  .main-content .container {
    padding: 6rem 15px;
  }

  .wc-memberships-members-area-pagination .wc-memberships-members-area-pagination-next {
    font-size: 20px;
  }

  .wc-memberships-members-area-pagination .wc-memberships-members-area-pagination-last {
    font-size: 26px;
    margin-top: 0;
  }

  /* footer */
  .social-links a, .social-links a .fa {
    padding: 7px;
  }

  .main-footer .row {
    position: relative;
    padding-top: 80px;
  }
  .main-footer .custom-logo-link {
    height: 60px;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  .main-footer .col-lg-4 {
    position: static;
  }
}
@media (max-width: 768px) {
  input,
textarea,
select {
    font-size: 16px !important;
  }

  .joinUsRenew, .singleAddtoCart {
    font-size: 2.5rem !important;
  }

  .cart a.single_add_to_cart_button,
.cart a.single_add_to_cart_button + .note-block, .singleAddtoCart + .note-block {
    margin-left: 0 !important;
  }

  .subscription_details td a {
    text-align: left !important;
    width: auto !important;
  }

  table th,
table td {
    font-size: 2rem !important;
  }

  .trading-sec h2 {
    font-size: 3rem;
  }

  .join-now {
    padding-left: 8rem;
    padding-right: 7rem;
  }

  .join-now a {
    padding-left: 3rem;
    font-size: 1.8rem;
    padding-right: 3rem;
  }

  .free-list li {
    font-size: 1.7rem;
  }

  .btn-links .btn {
    font-size: 1.6rem;
  }

  .trading-info-sec h2 {
    font-size: 4rem;
  }

  .trading-info-sec .h3,
.trading-info-sec h3 {
    font-size: 2.75rem;
  }

  .copy-right {
    font-size: 1rem;
  }

  main {
    min-height: 74vh;
  }

  .checkout .bitpay_logo {
    width: 18rem;
    margin-bottom: 11px;
  }

  .woocommerce-checkout-payment label[for=wc-square-credit-card-tokenize-payment-method] {
    padding-left: 2.5rem !important;
  }

  .checkout .woocommerce-checkout-review-order .woocommerce-form__label-for-checkbox .woocommerce-terms-and-conditions-checkbox-text a {
    padding-left: 0;
  }

  .subscription-detail .entry-summary {
    margin: 20px;
  }

  .checkout .woocommerce-checkout-review-order .woocommerce-form__label-for-checkbox .woocommerce-form__input-checkbox {
    width: 21px;
    height: 21px;
    padding-left: 0 !important;
  }

  #add_payment_method .woocommerce-Payment .woocommerce-PaymentMethods input[type=radio] {
    top: 3px;
  }

  .recordings-title {
    display: none;
  }

  .trading-recordings .card-body {
    padding-right: 0 !important;
  }
  .trading-recordings .card-title {
    width: calc(100% - 100px);
  }
  .trading-recordings .col-md-3 {
    position: static;
  }
  .trading-recordings .col-md-3 p {
    position: absolute;
    right: 15px;
    top: 8px;
  }

  .main-footer .col-lg-4 {
    order: 2;
    margin-top: 20px;
  }
  .main-footer .col-lg-8 {
    order: 1;
  }

  #wc-memberships-members-area-section {
    overflow-x: auto;
  }

  label {
    font-size: 1.8rem;
  }

  .sabai-widget-questions-search,
.search-position {
    position: static;
    width: 100%;
    margin-bottom: 32px;
  }

  .modal-dialog {
    max-width: 100%;
  }

  .sabai-entity-filter-form .sabai-row {
    margin-left: 0;
    margin-right: 0;
  }
  .sabai-entity-filter-form .sabai-col-md-12 {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }

  .sabai-entity-filter-form .sabai-taxonomy-term-0 {
    display: none !important;
  }

  .sabai-entity-filter-form .sabai-form-fields .sabai-form-type-select {
    margin-bottom: 0;
  }
  .sabai-entity-filter-form .sabai-form-fields .sabai-form-type-select select[name=questions_tags] {
    height: 100%;
    background-size: 16px;
  }

  .woocommerce-checkout .select2-container {
    margin-top: 0;
  }

  .checkout .woocommerce-checkout-review-order-table {
    margin-top: 0;
  }

  .astrike-styling {
    top: 8px;
    left: 12px;
  }

  .banner-content p {
    line-height: 2.8rem;
    font-size: 2.3rem;
  }
  .banner-content h5 {
    margin-bottom: 4rem !important;
    font-size: 3rem;
  }

  .free-list {
    margin-top: 20px;
  }

  .search-field {
    width: 100%;
  }

  .search-form label {
    display: block;
  }

  /* profile */
  .woocommerce-MyAccount-content .account-orders-table tr {
    border-bottom: 1px solid #7e7e7e;
  }
  .woocommerce-MyAccount-content .account-orders-table td {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  /* footer */
  .main-footer li {
    margin-bottom: 10px;
  }
  .main-footer [class*=col] img,
.main-footer [class*=col] span {
    margin-bottom: 10px !important;
  }
  .main-footer [class*=col]:last-of-type li {
    margin-bottom: 0 !important;
  }
}
@media (max-width: 575px) {
  html {
    font-size: 8px;
  }

  .account-block .woocommerce-Message--info {
    flex-direction: column;
  }
  .account-block .woocommerce-Message--info .woocommerce-Button {
    margin-bottom: 15px;
  }

  .account-orders-table td {
    border-left: 0 !important;
  }

  .trading-sec {
    padding-top: 0 !important;
    background: initial;
  }
  .trading-sec .col-md-7 {
    margin-top: 20px;
  }
  .trading-sec .img-wrap {
    position: static;
    width: 100%;
    margin-bottom: 20px;
  }

  .sabai-entity-filter-form .sabai-form-fields .sabai-form-type-select select[name=questions_tags] {
    margin-top: 20px;
  }

  table td,
table th {
    font-size: 1.5rem !important;
  }

  .checkout .woocommerce-checkout-review-order .woocommerce-form__label-for-checkbox .woocommerce-terms-and-conditions-checkbox-text a {
    padding-left: 39px;
  }
}
/** ==== Based on min-width ==== **/
@media (min-width: 1550px) {
  .container {
    max-width: 1450px;
  }
}
@media (min-width: 767px) {
  .main-footer .social-links {
    line-height: 1;
    margin-top: 15px;
  }

  .my_membership_settings {
    width: auto !important;
  }

  .search-form {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .search-form label {
    display: flex;
    align-items: center;
    margin-bottom: 0;
  }
  .search-form .screen-reader-text {
    min-width: 160px;
  }
  .search-form .search-submit {
    margin-left: 1rem;
  }
}
@media (min-width: 992px) {
  .menu-footer-nav-container {
    padding-left: 12%;
  }

  .main-sidebar + .main-content {
    width: calc(100% - 300px);
  }

  .main-header {
    padding-bottom: 2rem;
  }
  .main-header .menu {
    background-color: #1C1C22;
    box-shadow: 2px 2px 5px #00000096;
    text-transform: uppercase;
    border-radius: 14px;
    -webkit-border-radius: 14px;
    -moz-border-radius: 14px;
    -ms-border-radius: 14px;
    -o-border-radius: 14px;
  }
  .main-header .menu li {
    display: inline-block;
  }
  .main-header .menu li:first-child {
    padding-left: 20px;
  }
  .main-header .menu li:last-child {
    margin-left: 20px;
  }

  .side-bar.collapse {
    display: block !important;
  }
}
@media (min-width: 1200px) {
  .member-logged-in .login-form {
    max-width: 1140px;
  }
}
@media (min-width: 1024px) and (max-width: 1200px) {
  .main-header .custom-logo-link {
    max-width: 30rem;
  }
  .main-header .menu li {
    padding: 1.2rem 10px;
  }
  .main-header .menu li a {
    font-size: 1.9rem;
  }
}
@media (min-width: 1450px) and (max-width: 1550px) {
  .main-header .menu li {
    padding: 1.2rem 12px;
  }
  .main-header .menu li a {
    font-size: 1.7rem;
  }
}
/** =============== Footer CSS =============== **/
.copy-right {
  font-size: 1.25rem;
}
.copy-right sup {
  top: -0.3rem;
  padding: 0 3px;
}

.main-footer .menu-footer-nav-container {
  width: 100%;
}
.main-footer .custom-logo-link {
  width: 100%;
}
.main-footer .custom-logo-link img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.main-footer li {
  float: left;
  width: 50%;
}
.main-footer li a {
  color: #fff;
  font-weight: 500;
  text-decoration: none;
}
.main-footer ul {
  list-style: none;
  padding-left: 0;
}
.main-footer ul li:not(:last-child) {
  margin-bottom: 5px;
}