.nav-tabs {
    .nav-link {
        padding: 1rem !important;
        margin-bottom: 10px;
        line-height: 1;
        text-align: center;
        background-color: $primary !important;
        transition: all .2s ease;
        -webkit-transition: all .2s ease;
        -moz-transition: all .2s ease;
        -ms-transition: all .2s ease;
        -o-transition: all .2s ease;
        font-family: chypre-normal;
        font-weight: 500;
        font-size: 1.875rem;
        line-height: 1;
        background-color: #e7ebf2;
        border-radius: .625rem;
        -webkit-border-radius: .625rem;
        color: $white;
        text-decoration: none;
        border: 0;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        &.active {
            color: $gray-600;
        }
    }
}