
/* Trading recording css */

.trading-recordings{
    .trading-list{
        padding: 0 15px;
        a{
            &:hover{
                color: $primary !important;
            }
        }
    }
    h6{
        line-height: 1;
    }
    .card-body{
        padding-right: 2rem !important;
    }
    a{
        .col-md-3{
            p{
            font-family: $secondary-font;
            font-weight: 700;
            line-height: 1;
            }
        }
    }
}

.recordings-title{
    .col-md-3{
        padding-left: 0 !important;
    }
    [class*="col"]{
      font-size: 15px;
      font-family: $primary-font;
    }
}