/** =============== Homepage CSS =============== **/
.price-sec {
    * {
        font-family: $primary-font;
    }
}

.banner-content {
    z-index: 1;
    padding-bottom: 13vh;
    padding-top: 21rem;

    h5 {
        margin-bottom: 3.5rem !important;
    }

    h1 {
        font-size: 6.25rem;
    }

    h1,
    h5 {
        text-shadow: $text-shadow;
    }

    * {
        font-family: $primary-font;

    }

    p {
        line-height: 2.25rem;
        text-shadow: 0px 3px 6px #000000;
    }

}

.join-btn {
    margin-top: 5rem;
}

.price {
    font-size: 11.56rem;
    display: block;
    padding-top: 5rem;
}

.join-now {
    padding-left: 9rem;
    padding-right: 8rem;
    margin-top: 5rem;
}

.join-now+span {
    font-size: 1.563rem;
    font-family: $primary-font;
    line-height: 1;
    
    a {
        font-size: 1.563rem;
        color: $white;
        &:hover {
            border-color: $gray-600;
        }
    }
}

.btn-links {
    .btn {
        border: 5px solid $white;
        box-shadow: none;
        font-family: $primary-font;
        cursor: auto !important;
    }

    .col-md {
        &:nth-of-type(2) {
            background-color: #303F7C;
        }

        &:nth-of-type(3) {
            background-color: #404a72;
        }
    }

}

.trading-sec {
    background: -moz-linear-gradient(left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.37) 63%, rgba(255, 255, 255, 0) 100%);
    background: -webkit-linear-gradient(left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.37) 63%, rgba(255, 255, 255, 0) 100%);
    background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.9) 62%, rgba(255, 255, 255, 0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#00ffffff', GradientType=1);

p{
    font-size: 1.5rem;
    line-height: 2.5rem;
}
    .img-wrap {
        width: 51%;
        right: 0;
        left: auto;
        z-index: -1;

        >p {
            object-position: top;
            font-size: 1.5rem;
            line-height: 2.5rem;
        }
    }
}

.free-list {
    li {
        font-size: 2.1rem;
        margin-bottom: 5px;
        font-family: $primary-font;
        font-weight: 700;
    }
}

.trading-info-sec {
    .font-italic {
        font-weight: 500 !important;
    }
}

.join-now,
.join-now-2 {
    a {
        padding-left: 4rem;
        padding-right: 4rem;
    }
}

.social-links{
    a,a .fa{
        color: $white !important;
        font-size: 22px;
        padding: 2px;
        &:hover{
            color: $gray-600 !important;
        }
    }
}