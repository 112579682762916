.side-bar {
    width: 300px;
    padding: 2.5rem 0;

    h2 {
        font-family: $primary-font;
    }

    .icon-bar {
        height: 4px;
        background-color: $primary;
        display: -ms-inline-flexbox;
        display: block;
        border-radius: 2px;
        transition: .25s ease;
        margin-bottom: 4px;
        -webkit-transition: .25s ease;
        -moz-transition: .25s ease;
        -ms-transition: .25s ease;
        -o-transition: .25s ease;
    }

    li {
        a {
            display: block;
            padding: 15px;
            font-size: 1.7rem;
            letter-spacing: 1px;
            line-height: 1;
            color: $white !important;
            text-decoration: none;

            &:hover,
            &.active {
                background-color: $black;
                text-decoration: none;
                color: $gray-600 !important;
            }
        }
    }
}

.main-content {
    width: 100%;
    min-height: 100vh;

    .embed-responsive {
        box-shadow: 0px 0px 4px #00000029;
        border-radius: 0.5625rem;
    }

    .container {
        padding: 6rem;

        .card {
            box-shadow: 0px 0px 4px #00000029;
        }
    }

    h1 {
        font-weight: 500;
        margin-bottom: 2rem;
    }

    .card-body {
        a {
            text-decoration: none;

            &:hover {
                text-decoration: none;
            }
        }

        img {
            width: 6rem;
            height: 6rem;
        }
    }

}

.logo-img {
    width: 16rem;
    margin: 0 auto;
    height: 11rem;
}

.details-wrap {
    background-color: #556A96;
    padding: 1rem 1.3rem;

    span {
        font-family: $primary-font;

        &:nth-child(1) {
            font-size: 15px;
            line-height: 20px;
        }
    }

    span:nth-child(2) {
        font-size: 1.563rem;
    }

    h2 {
        font-size: 2.1rem;
    }
}

.level-trading {
    margin-right: -7px;
    margin-left: -7px;

    a {
        text-decoration: none;

        &:hover {
            text-decoration: none;
        }
    }

    .card {
        min-height: 20rem;
        margin-right: -7px;
        margin-left: -7px;

        [class*="col"] {
            padding-left: 7px;
            padding-right: 7px;

        }
    }
}

.main-info,
.sabai-main {

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-weight: 500;
    }

    .card-body {
        p {
            color: $body-color;
            font-size: 1.563rem;
            line-height: 2.188rem;
            font-weight: 500;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

.center-line {
    position: relative;

    span {
        padding-right: 15px;
    }

    &::before {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 15px;
        width: calc(100% - 15px);
        background-color: $primary;
        height: 3px;
        content: '';
        z-index: 0;
    }
}

.trading-list {
    .card {
        box-shadow: none;
        text-decoration: none;

        .card-body {
            box-shadow: none;
            text-decoration: none;

            p {
                font-size: 1.25rem;
                line-height: 1.5rem;
                margin-bottom: 0;
            }
        }
    }
}

.circle-ui {
    width: 3rem;
    height: 3rem;
    border: 2px solid $primary;

    &.filled {
        background-color: $primary;
    }
}

.circle-ui+.card-body {
    width: calc(100% - 3rem);
    padding-left: 1rem !important;

    h6 {
        line-height: 1;
    }
}

.mini-preview-anchor {
    width: 100%;
    margin-top: 3rem;
}

.mini-preview-wrapper {
    position: static !important;
    opacity: 1 !important;
    width: 100% !important;
    margin-top: -55px !important;
    height: 470px !important;
    overflow: visible !important;
    border: 0 !important;
    box-shadow: none !important;
}

.sabai-form-field-error input,
.sabai-form-field-error select,
.sabai-form-field-error textarea {
    outline: none !important;
}

