/** =============== Variables =============== **/

/* custom variable */
$primary-font:                   'mr-eaves-xl-modern', sans-serif;
$secondary-font:                 'chypre-normal', sans-serif;

/* bootstrap variables */
// Color 

$white:    #fff;
$gray-100: #f5f5f5;
$red:    #E01A1A; 
$gray-400: #7e7e7e;
$gray-600: #E0B501;
$gray-900: #1C1C22;
$black:    #000 ;
$yellow:  #FFD62B;
$primary:       #092258;
$secondary:     $gray-600;
$body-color:   #404040;
$light-blue: #E7EBF2;
// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.


$btn-padding-x:                 1.4rem;
$btn-font-size:                  2.813rem;
$btn-font-size-sm:               2.5rem;
$btn-padding-x-sm:               1.4rem;
$btn-font-size-lg:               3.75rem;
$btn-border-radius:              0.8125rem;
$btn-border-radius-lg:           1.125rem;
$btn-border-radius-sm:        0.8125rem;
$btn-font-weight:                700;
$btn-line-height:                1;

//typography
$h1-font-size:                   80px;
$h2-font-size:                   80px;
$h3-font-size:                   60px;
$h4-font-size:                   50px;
$h5-font-size:                   35px;
$h6-font-size:                   30px;
$headings-font-weight:           700;
$btn-line-height:                2.813rem;
$btn-line-height-sm:             3rem;
$btn-line-height-lg:             4.5rem;
$btn-padding-y-sm:              .375rem;
$border-width : 2px;
$border-color: $white;
$paragraph-margin-bottom:   2rem;
$link-color: $white;
// Cards
$card-border-radius:                0.5625rem;
// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

 $input-btn-font-size:         1.8rem;
$input-btn-border-width:      1px;
$input-height: 52px;
$input-font-family: $primary-font;
$input-placeholder-color: #1C1C2240;
$input-border-radius: 0.625rem;
$input-font-size: 32px;
$input-color: $black;
$text-shadow: 0px 3px 6px #00000064;
$input-font-weight: 700;