/** =============== Header CSS =============== **/

.gradient-bg {
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#060b2d+0,060e31+20,060e31+20,081641+27,081641+27,081641+32,092559+55,092559+66,092559+66,0b326e+100,070f33+100,2989d8+100,7db9e8+100 */
    background: rgb(6, 11, 45);
    /* Old browsers */
    background: -moz-linear-gradient(left, rgba(6, 11, 45, 1) 0%, rgba(6, 14, 49, 1) 20%, rgba(6, 14, 49, 1) 20%, rgba(8, 22, 65, 1) 27%, rgba(8, 22, 65, 1) 27%, rgba(8, 22, 65, 1) 32%, rgba(9, 37, 89, 1) 55%, rgba(9, 37, 89, 1) 66%, rgba(9, 37, 89, 1) 66%, rgba(11, 50, 110, 1) 100%, rgba(7, 15, 51, 1) 100%, rgba(41, 137, 216, 1) 100%, rgba(125, 185, 232, 1) 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(left, rgba(6, 11, 45, 1) 0%, rgba(6, 14, 49, 1) 20%, rgba(6, 14, 49, 1) 20%, rgba(8, 22, 65, 1) 27%, rgba(8, 22, 65, 1) 27%, rgba(8, 22, 65, 1) 32%, rgba(9, 37, 89, 1) 55%, rgba(9, 37, 89, 1) 66%, rgba(9, 37, 89, 1) 66%, rgba(11, 50, 110, 1) 100%, rgba(7, 15, 51, 1) 100%, rgba(41, 137, 216, 1) 100%, rgba(125, 185, 232, 1) 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, rgba(6, 11, 45, 1) 0%, rgba(6, 14, 49, 1) 20%, rgba(6, 14, 49, 1) 20%, rgba(8, 22, 65, 1) 27%, rgba(8, 22, 65, 1) 27%, rgba(8, 22, 65, 1) 32%, rgba(9, 37, 89, 1) 55%, rgba(9, 37, 89, 1) 66%, rgba(9, 37, 89, 1) 66%, rgba(11, 50, 110, 1) 100%, rgba(7, 15, 51, 1) 100%, rgba(41, 137, 216, 1) 100%, rgba(125, 185, 232, 1) 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#060b2d', endColorstr='#7db9e8', GradientType=1);
    /* IE6-9 */
}

.main-header {
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#060b2d+0,060e31+20,060e31+20,081641+27,081641+27,081641+32,092559+55,092559+66,092559+66,0b326e+100,070f33+100,2989d8+100,7db9e8+100 */
    background: rgb(6, 11, 45);
    /* Old browsers */
    background: -moz-linear-gradient(left, rgba(6, 11, 45, 1) 0%, rgba(6, 14, 49, 1) 20%, rgba(6, 14, 49, 1) 20%, rgba(8, 22, 65, 1) 27%, rgba(8, 22, 65, 1) 27%, rgba(8, 22, 65, 1) 32%, rgba(9, 37, 89, 1) 55%, rgba(9, 37, 89, 1) 66%, rgba(9, 37, 89, 1) 66%, rgba(11, 50, 110, 1) 100%, rgba(7, 15, 51, 1) 100%, rgba(41, 137, 216, 1) 100%, rgba(125, 185, 232, 1) 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(left, rgba(6, 11, 45, 1) 0%, rgba(6, 14, 49, 1) 20%, rgba(6, 14, 49, 1) 20%, rgba(8, 22, 65, 1) 27%, rgba(8, 22, 65, 1) 27%, rgba(8, 22, 65, 1) 32%, rgba(9, 37, 89, 1) 55%, rgba(9, 37, 89, 1) 66%, rgba(9, 37, 89, 1) 66%, rgba(11, 50, 110, 1) 100%, rgba(7, 15, 51, 1) 100%, rgba(41, 137, 216, 1) 100%, rgba(125, 185, 232, 1) 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, rgba(6, 11, 45, 1) 0%, rgba(6, 14, 49, 1) 20%, rgba(6, 14, 49, 1) 20%, rgba(8, 22, 65, 1) 27%, rgba(8, 22, 65, 1) 27%, rgba(8, 22, 65, 1) 32%, rgba(9, 37, 89, 1) 55%, rgba(9, 37, 89, 1) 66%, rgba(9, 37, 89, 1) 66%, rgba(11, 50, 110, 1) 100%, rgba(7, 15, 51, 1) 100%, rgba(41, 137, 216, 1) 100%, rgba(125, 185, 232, 1) 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#060b2d', endColorstr='#7db9e8', GradientType=1);
    /* IE6-9 */
    margin-bottom: 2.5rem;
    a{
        text-decoration: none;
        &:hover{
            text-decoration: none; 
        }
    }
    &.transparent-header{
        background: none;
        margin-bottom: 0;
    }

    .container-fluid {
        padding: 0 6vw;
    }

    .custom-logo-link {
        max-width: 32rem;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    ul {
        padding-left: 0;
        list-style: none;
    }

    .menu {
        margin-bottom: 0;
        // padding-top: .6rem;
        // padding-bottom: .6rem;
        display: flex;
        align-items: center;

        li {

            padding: 1.2rem 15px;

            a {
                font-size: 2.188rem;
                font-weight: 400;
                color: $white;
              
            }

            &:last-child {
                border-radius: 0 14px 14px 0;
                -webkit-border-radius: 14px;
                -moz-border-radius: 14px;
                -ms-border-radius: 14px;
                -o-border-radius: 14px;
                text-transform: uppercase;
                background-color: $gray-600;
                display: inline-block;
                font-family: $primary-font;
                color: #1c1c22;
                text-align: center;
                vertical-align: middle;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
                border: 2px solid transparent;
                font-size: 30px !important;
                line-height: 1;
                transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
                -webkit-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
                -moz-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
                -ms-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
                -o-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-14pxin-out;
                border-radius: 0 14px 14px 0;

                &:hover {
                    background-color: #a88908;

                    a {
                        color: $white !important;

                    }
                }

                a {
                    color: #1c1c22;
                    font-weight: 700;
                    font-size: 2.5rem;
                }
            }
        }

        .nav-link {
            font-family: $primary-font;
            color: $white;
        }

    }

}

.scroll-effect {
    padding-bottom: 15px !important;
    transition: all .5s ease;
}

.current_page_item {
    a {
        color: $gray-600;
    }
}

.transparent-header{
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 2;
}

.navbar-collapse{
    &.show{
        left: 0;
    }
}

.menu-open{
    left: 0!important;
    opacity: 1!important;
}

.trade-heading{
    line-height: 2.188rem;
    display: flex;
    align-items: center;
    span{
    width: .6rem;
    height: .6rem;
    display: inline-block;
    background-color: $primary;
    border-radius: 50%;
    margin: 0 2.5rem;
    }
}