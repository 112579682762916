/** =============== Global CSS =============== **/
.inner-header+main {
  margin-top: 22.5rem;
}

.stop-scrolling {
  overflow: hidden;
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0 !important;
  right: 0;
  bottom: 0;
}

article {
  position: relative;
}

//images
.o-contain,
.o-cover {
  width: 100%;
  height: 100%;
}

.o-contain {
  object-fit: contain;
}

.o-cover {
  object-fit: cover;
}

.img-wrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  object-fit: cover;
  height: 100%;
}

p:empty {
  display: none;
}

//buttons

.btn-yellow {
  background-color: $gray-600;
  color: $black;

  &:hover {
    color: $primary !important;
    background-color: $gray-600 !important;
    border-color: $white !important;
  }
}



.btn-blue,
.woocommerce-button--previous,
.woocommerce-button--next {
  background-color: #2D0565 !important;
  color: $white !important;
  &:hover{
    color: $gray-600 !important;
  }
}

.btn {
  text-transform: uppercase;
  border: 2px solid $white;
  box-shadow: 2px 3px 6px $black;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

//color

.gray-bg {
  background-color: $gray-900;
}

//spacing

.section-divider {
  padding-top: 2.5rem;
  padding-bottom: 3rem;
}

input[type="submit"] {
  @extend .btn-yellow;
}

main {
  min-height: 100vh;
}

.astrike-styling {
  position: absolute;
  color: $red;
  top: 24px;
  left: 0;
  font-size: 30px;
}

//modal
.modal-dialog {
  max-width: 75vw;
  margin: 30px auto;
  padding: 0 15px;

  .modal-content {
    border: 1px solid #707070;
    border-radius: 0;
    padding: 1.2rem;
    height: 100%;

    .modal-header {
      border-bottom: 1px solid #7e7e7e;

      h1 {
        margin-bottom: 0;
        font-weight: 700;
      }
    }

    .close {
      margin: 0;
      font-size: 15px;
      box-shadow: none;
      color: #ccc;
      line-height: 1;
      z-index: 2;
      padding: 0;
      -webkit-appearance: none;
      border: 0;
      text-transform: uppercase;
      margin-left: auto;
      background-color: transparent;

      &:focus {
        outline: none;
      }
    }
  }
}

// alert message
.woocommerce-message,
.woocommerce-info,
.woocommerce-error,
.woocommerce-noreviews,
p.no-comments {
  background-color: transparent !important;
  padding-left: 0;
  line-height: 1.2 !important;
  font-size: 18px !important;
  width: 100% !important;
}

//pagination

.post-pagination {
  .page-numbers {
    list-style-type: none;
    display: flex;
    padding-left: 0;
    justify-content: center;
    font-size: 15px;
    align-items: center;

    li {
      margin: 0 2px;
      display: flex;
      font-family: $primary-font;

      a {
        text-decoration: none;
      }
    }
  }
}

td:empty,th:empty{
  display: none;
}

.card{
  border: 0 !important;
}