 input[type="email"],
 input[type="date"],
 input[type="datetime"],
 input[type="datetime-local"],
 input[type="month"],
 input[type="number"],
 input[type="password"],
 input[type="search"],
 input[type="tel"],
 input[type="text"],
 input[type="time"],
 input[type="week"],
 input[type="url"],
 select,
 textarea,
 .select2-selection__rendered,
 [type=search],
 .woocommerce #payment div.payment_method_square_credit_card .wc-square-credit-card-hosted-field {
     @extend .form-control;
 }

 input[type="submit"],
 button[type="submit"],
 .sabai-questions-askquestion input[type=submit],
 #place_order,
 .simplefavorite-button,
 .woocommerce-button,
 .woocommerce-Button,
 .single_add_to_cart_button,
 .add_to_cart_button {
     @extend .btn;
     @extend .btn-sm;
     box-shadow: 0px 3px 6px #000000ED;
     border: 2px solid $white !important;
     border-radius: 1.125rem;
     padding-left: 3rem;
     padding-right: 3rem;
     font-family: $primary-font;
 }

 textarea.form-control {
     height: 245px;
 }

 .contact-form {
     margin-bottom: 1rem;

     p {
         text-align: right;
     }
 }

 textarea {
     resize: none;
 }

 .modal {

     .form-control,
     [type=search],
     input[type=date],
     input[type=datetime-local],
     input[type=datetime],
     input[type=email],
     input[type=month],
     input[type=number],
     input[type=password],
     input[type=search],
     input[type=tel],
     input[type=text],
     input[type=time],
     input[type=url],
     input[type=week],
     select,
     textarea {
         height: 35px;
     }
 }

 label,
 .sabai-form legend {
     font-size: 1.5rem;
     line-height: 1;
     font-weight: 400;
     width: 100%;
 }

 input[type=submit],
 button[type="submit"],
 #place_order,
 .woocommerce-button,
 .woocommerce-Button,
 .single_add_to_cart_button,
 .add_to_cart_button,
 .sabai-questions-askquestion input[type=submit],
 .sabai-content-btn-edit-questions,
 .simplefavorite-button {
     background-color: $gray-600 !important;
     color: $black !important;

     &:hover {
         color: $primary !important;
     }

 }

 .add_to_cart_button {
     @extend .btn-block;
 }

 .sabai-main {
     .card-body {
         @extend .card;
         @extend .mb-3;
         @extend .border;
         box-shadow: 0 0 4px rgba(0, 0, 0, .16);
     }
 }


 .woocommerce-form-login {
     .form-row {
         .label {
             @extend .d-block;
         }
     }

     .woocommerce-form__input-checkbox+span {
         position: relative;
         left: 1rem;
         top: 4px;
     }

     .password-input {
         width: 100%;
     }
 }

 .woocommerce-form-login__rememberme {
     @extend .w-100;
     display: flex;
     align-items: center;

     .woocommerce-form__input {
         margin-right: 6px;
     }
 }

 .woocommerce-error {
     @extend .list-unstyled;
 }

 .wpcf7-not-valid-tip,
 .woocommerce-error li,
 .sabai-form-field-error {
     @extend .alert;
     @extend .alert-danger;
     @extend .list-unstyled;
     @extend .bg-transparent;
     font-size: 18px !important;
     color: $red !important;
     border: 0;
     padding: 0;

     li {
         line-height: 1.2;
     }
 }

 .checkout {
     @extend .row;

     .payment_method_cod,
     .woocommerce-checkout-payment .wc_payment_method {
         display: flex;
         flex-wrap: wrap;

         label {
             display: flex;

             img {
                 margin-left: 10px;
             }
         }

         .input-radio {
             width: 15px;
         }

         label {
             width: calc(100% - 15px);
             padding-left: 8px;
         }

         .payment_method_cod,
         .payment_method_bitpay_checkout_gatewa {
             width: 100%;
             padding-left: 23px;
         }
     }

     .woocommerce-NoticeGroup {
         @extend .col-lg-12;

         .woocommerce-error li {
             display: flex;

             strong {
                 display: block;
                 padding-right: 5px;
             }
         }
     }

     .col2-set {
         @extend .col-lg-7;
     }

     .woocommerce-checkout-review-order {
         @extend .col-lg-5;

         .woocommerce-form__label-for-checkbox {
             .woocommerce-form__input-checkbox {
                 width: 25px;
                 height: 25px;
                 top: 1rem;
             }

             .woocommerce-terms-and-conditions-checkbox-text {
                 padding-left: 10px;

                 a {
                     padding-left: 39px;
                 }
             }
         }
     }

     .place-order,
     .payment_box p {
         font-size: 1.25rem;
         line-height: 1.5rem;
     }

     .bitpay_logo {
         width: 4rem;
         margin-top: .5rem;
     }

 }


 .woocommerce-checkout {
     .woocommerce-error {
         width: 100%;
         padding-left: 15px;
     }

     .woocommerce-message {
         @extend .alert;
         @extend .alert-success;
         background-color: transparent;
         border: 0;
         padding: 0;

         .wc-forward {
             display: none;
         }
     }

     .site-content {
         .intro-sec {
             @extend .container;
         }
     }

     .woocommerce-billing-fields__field-wrapper {
         @extend .row;

         .form-row {
             @extend .col-sm-6;
         }
     }
 }

 .wc_payment_methods {
     @extend .list-unstyled;
 }

 [type="checkbox"]:not(:checked),
 [type="checkbox"]:checked {
     position: relative;
     padding-left: 1.95em;
     cursor: pointer;
 }

 [type="checkbox"]:not(:checked):before,
 [type="checkbox"]:checked:before {
     content: '';
     position: absolute;
     left: 0;
     top: 0;
     width: 1.25em;
     height: 1.25em;
     border: 2px solid $primary;
     background: $white;
     box-shadow: inset 0 1px 3px rgba(0, 0, 0, .1);
 }

 [type="checkbox"]:not(:checked):after,
 [type="checkbox"]:checked:after {
     content: "\f00c";
     position: absolute;
     top: 3px;
     left: 3px;
     font-size: 1em;
     line-height: 0.8;
     color: $primary;
     transition: all .2s;
     -webkit-font-smoothing: antialiased;
     display: inline-block;
     font-style: normal;
     font-variant: normal;
     text-rendering: auto;
     line-height: 1;
     font-family: Font Awesome\ 5 Free;
     font-weight: 900;
 }

 [type="checkbox"]:not(:checked):after {
     opacity: 0;
     transform: scale(0);
 }

 .subscription-detail {
     .product-type-simple.shipping-taxable {
         @extend .row;
     }

     .woocommerce-product-gallery {
         @extend .col-lg-4;
     }

     .entry-summary {
         @extend .col-lg-8;
         @extend .card;
         box-shadow: 0 0 4px rgba(0, 0, 0, .16);
         border: 2px solid $white !important;
         padding: 20px;
     }
 }

 #place_order {
     width: 100%;
 }

 .woocommerce-thankyou-order-received {
     font-size: 3.75rem;
 }

 .woocommerce-order-details,
 .woocommerce-customer-details {
     @extend .bg-light;
     padding: 2rem;
     margin-bottom: 2rem;
 }

 .woocommerce-orders-table--orders+.woocommerce-customer-details {
     margin-top: 2rem;
 }

 .woocommerce-order-details h2,
 .woocommerce-customer-details h2,
 .subscription_details+h2,
 .order_details+header h2 {
     font-size: 3.125rem;
     border-bottom: 1px solid $gray-400;
     margin-bottom: 1.5rem;
     margin-top: 1.5rem;
 }

 .woocommerce-info {
     @extend .alert;
     @extend .alert-info;
     background-color: transparent !important;
     border: 0;
     padding: 0;
 }

 .optional {
     display: none;
 }

 .sabai-questions-askquestion input[type=submit] {
     float: right;
 }

 .sabai-entity-field-name-questions-tags {
     .select2-container-multi {
         ul {
             display: flex;
             align-items: center;

             li {
                 background-color: transparent !important;
             }
         }
     }
 }

 .sabai-questions-main {
     .ForumTitle+ul li {
         @extend .mb-4;
     }
 }

 .woocommerce-form-row {
     span {
         width: 100%;
     }
 }

 .form-row {
     margin-left: 0;
     margin-right: 0;
 }

 .sabai-widget-questions-search {
     .twitter-typeahead {

         input {
             @extend .form-control;
             width: 100% !important;
             height: 52px !important;
             padding: .375rem .75rem !important;
         }
     }
 }

 .sabai-questions-search-category,
 .sabai-questions-search-submit {
     @extend .d-none;
 }

 .woocommerce-MyAccount-content {
     padding: 0 .5rem;

     .edit-account {
         @extend .card;
         @extend .card-body;
         @extend .col-lg-6;

         .woocommerce-Button {
             margin-top: 2rem;
         }
     }

     .my_account_memberships {
         td {
             line-height: 1;
         }
     }

     table {
         width: 100%;
         border: 1px solid $gray-400;

         th,
         td {
             padding: .7rem 15px;
         }

         .sort-status,
         .wc-memberships-members-area-pagination {
             @extend .d-flex;
             @extend .align-items-center;
             white-space: nowrap;
         }

         a {
             text-decoration: none;
             line-height: 1;
         }

         thead tr {
             border-bottom: 1px solid $gray-400;

             th {
                 border-left: 1px solid $gray-400;
                 font-weight: 700;
             }
         }
     }

     a {
         text-decoration: none;
     }

     .woocommerce-button--previous,
     .woocommerce-button--next {
         @extend .mt-4;
     }

     .account-orders-table {
         td {
             padding-bottom: 2rem;
             padding-top: 2rem;
         }

         th {
             padding-bottom: 1rem;
             padding-top: 1rem;
         }
     }

     .woocommerce-button {
         line-height: 1;
     }

     h4 {
         font-size: 1.563rem;
         color: $body-color;
         font-weight: 700;
     }

     p {
         font-weight: 400;
         margin-bottom: 2rem !important;

         &:first-of-type {
             a {
                 font-weight: 700;
             }
         }
     }

 }


 .my_account_orders {
     tr {
         @extend .h6;
         font-weight: 500;
     }

     a {
         text-decoration: underline;
     }

     th,
     td {
         padding: .7rem 15px;
         border-left: 1px solid $gray-400;
     }

     thead {
         tr {
             th {
                 font-weight: 500;
             }
         }
     }

 }

 .woocommerce {

     .woocommerce-MyAccount-navigation {
         @extend .mw-100;
         @extend .bg-transparent;
         box-shadow: none !important;

         a {
             text-decoration: none;
         }

         >ul {
             @extend .pl-0;
             @extend .w-100;
             @extend .row;
             margin-right: -7px;
             margin-left: -7px;

             li.woocommerce-MyAccount-navigation-link {
                 padding: 0 7px !important;
                 margin-bottom: 10px;
                 line-height: 1;
                 text-align: center;
                 transition: all .2s ease;
                 -webkit-transition: all .2s ease;
                 -moz-transition: all .2s ease;
                 -ms-transition: all .2s ease;
                 -o-transition: all .2s ease;

                 a {
                     @extend .text-center;
                     @extend .w-100;
                     font-family: $secondary-font;
                     font-weight: 500;
                     font-size: 1.5rem;
                     line-height: 1;
                     padding: 1rem;
                     background-color: $light-blue;
                     border-radius: 0.625rem;
                     -webkit-border-radius: 0.625rem;
                     -moz-border-radius: 0.625rem;
                     -ms-border-radius: 0.625rem;
                     -o-border-radius: 0.625rem;
                     height: 100%;
                     display: flex;
                     align-items: center;
                     justify-content: center;

                     &:hover {
                         background-color: $primary;
                         @extend .text-white;
                     }

                 }

                 &.is-active {
                     a {
                         @extend .bg-primary;
                         @extend .text-white;
                     }
                 }

                 &:last-child {
                     @extend .mr-0;
                 }
             }
         }
     }
 }

 .my_account_memberships {
     td {
         font-size: 1.25rem;
     }
 }


 .lounge-tabs {
     margin-top: 4rem;

     .nav-tabs {
         @extend .row;
         margin-bottom: 4rem;

         li {
             @extend .col-md-4;
             @extend .mb-4;
         }
     }

     .tab-content {
         padding-left: 2rem;
         padding-right: 2rem;
     }
 }

 #add_payment_method {
     @extend .row;
     @extend .mx-0;


     .woocommerce-Payment {
         @extend .col-lg-6;
         @extend .card;
         @extend .card-body;

         .woocommerce-PaymentMethods {
             @extend .list-unstyled;

             input[type="radio"] {
                 @extend .position-absolute;
                 top: 16px;
             }

             label[for="payment_method_square_credit_card"] {
                 display: flex;
                 padding-left: 21px;
             }
         }

         #place_order {
             width: auto !important;
         }
     }
 }

 label[for="wc-square-credit-card-tokenize-payment-method"] {
     padding-left: 1.2rem !important;
 }

 .wc-memberships-members-area-pagination {

     .wc-memberships-members-area-pagination-next,
     .wc-memberships-members-area-pagination-last {
         font-size: 18px;
     }

     .wc-memberships-members-area-pagination-last {
         margin-left: 4px;
         margin-top: 4px;
     }
 }

 .my-membership-details {
     table.shop_table_responsive tr td {
         display: table-cell;
         text-align: left;

         &:before {
             display: none;
         }
     }
 }

 .woocommerce #payment div.payment_method_square_credit_card .wc-square-credit-card-hosted-field * {
     height: 100% !important;
 }

 .account-block .woocommerce #payment div.payment_method_square_credit_card .wc-square-credit-card-hosted-field,
 .account-block input:not([type=checkbox]):not([type=radio]),
 .woocommerce-checkout-payment .payment_method_square_credit_card .wc-square-credit-card-hosted-field {
     height: 39px !important;
     line-height: 0.5;
 }

 .woocommerce-PaymentMethod {
     >input[type="radio"] {
         @extend .position-absolute;
     }
 }

 .woocommerce-NoticeGroup-checkout {
     padding-left: 0;
 }

 .account-orders-table {
     .woocommerce-orders-table__cell-order-actions {
         a {
             margin-bottom: 15px;
             margin-left: 15px;
         }
     }
 }

 .woocommerce-order-pay #order_review {
     @extend .card;
     @extend .card-body;
 }

 .payment_box.payment_method_bitpay_checkout_gateway {
     @extend .d-block;
     @extend .h-auto;
 }

 table.sv-wc-payment-gateway-my-payment-methods-table .sv-wc-payment-gateway-payment-method-header-details span {
     display: block !important;
 }

 .woocommerce {
     table {
         tfoot {
             border-top: 1px solid $gray-400;
         }
     }
 }

 .woocommerce .woocommerce-MyAccount-navigation>ul li.woocommerce-MyAccount-navigation-link.is-active a:hover {
     background-color: $primary !important;
 }

 .sabai-questions-answers {
     @extend .bg-transparent;

     .sabai-questions-vote-count {
         display: flex;
         justify-content: center;
         align-items: center;
         height: 100%;

     }


     &.p-4 {
         padding: 0 !important;
     }

     >.sabai-entity {
         @extend .bg-white;
         @extend .p-4;
         @extend .mb-3;
     }
 }

 .subscription-detail .product_cat-uncategorized {
     @extend .row;
     display: flex !important;
 }

 .simplefavorite-button.active {
     opacity: 1;
 }

 .sabai-alert {
     @extend .alert;
 }

 .sabai-alert-danger {
     @extend .alert-danger;
 }

 .woocommerce_account_subscriptions {
     p {
         @extend .d-block;
     }
 }

 .classroom-detail {

     .simplefavorite-button,
     .no_subscriptions .woocommerce-Button {
         @extend .float-right;
     }
 }

 .forum-detail,
 .go-back {
     @extend .mx-0;
 }

 .cart {
     @extend .row;
     .note-block{
         font-size: 16px !important;
     }
     .single_add_to_cart_button,
     .note-block {
         @extend .col-md-5;
         @extend .m-0;
         font-size: 2rem;
     }

     button.single_add_to_cart_button {
         @extend .order-md-1;
     }

     a.single_add_to_cart_button {
         @extend .order-md-2;
         margin-left: 16px!important;
     }

     button.single_add_to_cart_button+.note-block {
         @extend .order-md-3;
     }

     a.single_add_to_cart_button+.note-block {
         @extend .order-md-4;
         margin-left: 16px !important;
     }
 }

 .singleAddtoCart+.note-block{
    margin-left: 16px !important;
 }