/** =============== Main Typography =============== **/

html {
  font-size: 16px;
}

body {
  font-size: 1.875rem;
  font-weight: 400;
  line-height: 3.125rem;
  overflow-x: hidden;
  font-family: $secondary-font;
  color: $primary;
}

h2 {
  font-weight: 600;
}

a{
  font-family: $primary-font;
  text-decoration: underline;
  display: inline-block;
  color: $primary;
  &:hover{

    color: $secondary !important;
  }
}

img{
  display: block;
}