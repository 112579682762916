/** =============== Footer CSS =============== **/

.copy-right{
    font-size:  1.25rem;
    sup {
        top: -0.3rem;
        padding: 0 3px;
    }
}

.main-footer{

    .menu-footer-nav-container{
        width: 100%;
    }
    .custom-logo-link{
        width: 100%;
        img{
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
    li{
        float: left;
        width: 50%;
        a{
            color: $white;
            font-weight: 500;
            text-decoration: none;
        }
    }
    ul{
        list-style: none;
        padding-left: 0;
        li:not(:last-child){
            margin-bottom: 5px;
          }
    }  
}